import { Accordion, Text } from '@connect-assistance/connect-ui';
import styles from './index.module.css';
import { ProviderQuoteStatusLabels } from '../utils';
import useProviderQuoteHistory from '../hooks/useProviderQuoteHistory';
import { QuoteAccordionContent } from './QuoteAccordionContent';

interface QuoteHistoryProps {
	serviceId: string;
	branch: string;
}

export function QuoteHistory({ serviceId, branch }: QuoteHistoryProps): JSX.Element {
	const { isLoading, error, quoteHistory } = useProviderQuoteHistory(serviceId);

	if (isLoading) return <Text>Loading quote history...</Text>;
	if (error) return <Text>{`Error: ${error.message}`}</Text>;

	return (
		<Accordion.Root className={styles.accordionRoot} type="multiple" defaultValue={[quoteHistory?.[0].name]}>
			{quoteHistory.map((quote) => {
				const sortedQuoteLogs = quote.logs.sort(
					(a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
				);
				const quoteLastSnapshotStatus = sortedQuoteLogs[0].snapshot.status;
				const accordionTriggerLabel = `${quote.name} (${ProviderQuoteStatusLabels[quoteLastSnapshotStatus]})`;

				return (
					<Accordion.Item key={quote.name} value={quote.name}>
						<Accordion.Trigger>{accordionTriggerLabel}</Accordion.Trigger>
						<Accordion.Content>
							<div className={styles.contentWrapper}>
								{sortedQuoteLogs.map(
									({ user, timestamp, snapshot: { name, description, note, total, status, items } }) => (
										<QuoteAccordionContent
											key={timestamp}
											quoteLog={{ creatorName: user.name, description, note, total, status, items, timestamp, name }}
											branch={branch}
										/>
									)
								)}
							</div>
						</Accordion.Content>
					</Accordion.Item>
				);
			})}
		</Accordion.Root>
	);
}
