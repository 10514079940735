import successIcon from '@assets/imgs/dashboard-icons/icon_success.svg';
import warningIcon from '@assets/imgs/dashboard-icons/icon_warning.svg';
import './style.scss';

const VARIANT_SUCCESS = 'success';
const VARIANT_WARNING = 'warning';

const variantIcons = {
	[VARIANT_SUCCESS]: {
		icon: 'icon_success.svg',
		alt: 'Success Icon',
		src: successIcon,
	},
	[VARIANT_WARNING]: {
		icon: 'icon_warning.svg',
		alt: 'Warning Icon',
		src: warningIcon,
	},
};

interface AlertTagProps {
	variant: typeof VARIANT_SUCCESS | typeof VARIANT_WARNING;
	text: string;
	customClass?: string;
}

const AlertTag = ({ variant, text, customClass }: AlertTagProps) => {
	const iconInfo = variantIcons[variant];

	return (
		<div className="alert-tag-container">
			<div className={`alert-tag ${variant} ${customClass ?? ''}`}>
				<img src={iconInfo.src} alt={iconInfo.alt} className="icon" />
				<span className="text">{text}</span>
			</div>
		</div>
	);
};

export default AlertTag;
