import { HELIOS_SERVICE_STATUS } from '@utils/constants';
import Feature from '@utils/FeatureFlags';
import { FEATURE_FLAG } from '@utils/FeatureFlags/constants';
import { canConfirmThisService } from '@views/Service/Service.util';

export const ConfirmButton = ({
	editMode,
	setAction,
	setShowConfirmModal,
	status,
	warnings,
}: {
	editMode: boolean;
	setAction: (value: string) => void;
	setShowConfirmModal: (value: boolean) => void;
	status?: HELIOS_SERVICE_STATUS;
	warnings?: string[];
}): JSX.Element | null => {
	const handleOnClick = () => {
		setAction('confirm');
		setShowConfirmModal(true);
	};
	const isAllowedToConfirm = canConfirmThisService(warnings);
	if (
		[HELIOS_SERVICE_STATUS.QUEUED, HELIOS_SERVICE_STATUS.HOLD_INSPECTION].includes(status as HELIOS_SERVICE_STATUS) &&
		!editMode &&
		isAllowedToConfirm
	) {
		return (
			<button
				className="action-buttons-container__btn action-buttons-container__btn__btn-confirm"
				onClick={handleOnClick}
			>
				CONFIRM
			</button>
		);
	} else return null;
};

export const FinishButton = ({
	editMode,
	allowedToFinishFromEOS,
	setShowConfirmModal,
	setAction,
	status,
}: {
	editMode: boolean;
	allowedToFinishFromEOS: boolean;
	setShowConfirmModal: (value: boolean) => void;
	setAction: (value: string) => void;
	status?: HELIOS_SERVICE_STATUS;
}): JSX.Element => {
	const handleOnClick = () => {
		setAction('finish');
		setShowConfirmModal(true);
	};

	return (
		<Feature name={FEATURE_FLAG.SERVICE_DETAIL__ACTION_BUTTONS__FINISH}>
			{allowedToFinishFromEOS && !editMode && status === HELIOS_SERVICE_STATUS.ACTIVE && (
				<button
					className="action-buttons-container__btn action-buttons-container__btn__btn-finish"
					onClick={handleOnClick}
				>
					FINISH
				</button>
			)}
		</Feature>
	);
};

export const EditButton = ({
	editMode,
	setEditMode,
	status,
}: {
	editMode: boolean;
	setEditMode: (value: boolean) => void;
	status?: HELIOS_SERVICE_STATUS;
}): JSX.Element | null => {
	if (
		!editMode &&
		status &&
		[HELIOS_SERVICE_STATUS.QUEUED, HELIOS_SERVICE_STATUS.HOLD_INSPECTION, HELIOS_SERVICE_STATUS.ACTIVE].includes(status)
	) {
		return (
			<Feature name={FEATURE_FLAG.SERVICE_DETAIL__ACTION_BUTTONS__EDIT}>
				<button
					className="action-buttons-container__btn action-buttons-container__btn__btn-edit"
					onClick={() => setEditMode(true)}
				>
					EDIT
				</button>
			</Feature>
		);
	} else return null;
};

export const EditCancelButton = ({
	editMode,
	setEditMode,
	clearEditServiceData,
}: {
	editMode: boolean;
	setEditMode: (value: boolean) => void;
	clearEditServiceData: () => void;
}): JSX.Element | null => {
	const handleEditCancel = () => {
		clearEditServiceData();
		setEditMode(false);
	};

	if (editMode) {
		return (
			<button
				className="action-buttons-container__btn action-buttons-container__btn__btn-cancel"
				onClick={() => handleEditCancel()}
			>
				CANCEL
			</button>
		);
	} else return null;
};

export const EditConfirmButton = ({
	editMode,
	setShowConfirmModal,
	setAction,
}: {
	editMode: boolean;
	setShowConfirmModal: (value: boolean) => void;
	setAction: (value: string) => void;
}): JSX.Element | null => {
	const handleOnClick = () => {
		setAction('edit');
		setShowConfirmModal(true);
	};

	if (editMode) {
		return (
			<Feature name={FEATURE_FLAG.SERVICE_DETAIL__ACTION_BUTTONS__CONFIRM}>
				<button
					className="action-buttons-container__btn action-buttons-container__btn__btn-edit-confirm"
					onClick={handleOnClick}
				>
					SAVE CHANGES
				</button>
			</Feature>
		);
	} else return null;
};
