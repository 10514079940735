// External imports
import React, { useContext } from 'react';
// Global imports
import { DEFAULT_VEHICLE_MAKE_MODEL, HDI_MEXICO_ID, VANTI_ID, VISIT_TYPE_OPTIONS } from '@utils/constants';
import { getServiceLayoutSection, getServiceTypeLabel, getYearOptions } from '@utils/Utils';
import {
	ATLAS_MEXICO_ID,
	CLUPP_MEXICO_ID,
	CRABI_MEXICO_ID,
	KAVAK_MEXICO_ACCOUNT_IDS,
	AFIRME_MEXICO_ID,
} from '@utils/constants';
import { Context } from '@context/Context';
// Local imports
import { DispatchFormContext } from '../../context/Context';
import S from './styles';
import T from '../../texts';
import { AppContext, ServiceType } from '@interfaces';
import { accountLogos } from './constants';
import { useServiceForm } from '../../hooks/useCreateService';
import CheckboxButton from '@components/DesignSystem/CheckboxButton';

const armorLevelOptions = ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'];
const vehicleTypeOptions = ['Auto', 'Moto', 'Equipo Pesado'];
const yearOptions = getYearOptions();

const ServiceDetails = (): React.ReactElement => {
	const { user } = useContext(Context) as AppContext;
	const {
		client,
		vehicle,
		service,
		serviceTypes,
		vehicleMakes,
		vehicleModels,
		detailsErrors: errors,
		setClientAttribute,
		setVehicleAttribute,
		setServiceAttribute,
	} = useContext(DispatchFormContext);
	const { setVehicleModelsByMake } = useServiceForm();

	const handleChangeVip = () => setServiceAttribute('vip', !service.vip);

	const serviceLayoutSection = getServiceLayoutSection(user.account);
	const displayVantiHogarFields = VANTI_ID === user.account;
	const displayVehicleFields = user.account !== VANTI_ID;

	const displayExternalIdLabel =
		user.account === VANTI_ID ? T.serviceDetails.externalIdVanti : T.serviceDetails.vehicleExternalId;

	return (
		<S.FormContainer borderType="shadow-highlight">
			<S.FormSectionTitle>{T.serviceDetails.sectionTitleClient}</S.FormSectionTitle>
			<S.InputsContainer className={user.account === ATLAS_MEXICO_ID ? 'pre-highlight' : undefined} $section="client">
				<S.Input
					id={T.serviceDetails.clientName.id}
					labelText={T.serviceDetails.clientName.label}
					value={client.name}
					onChange={setClientAttribute}
					required
					$gridArea="input-1"
					error={errors?.name}
				/>
				<S.Input
					id={T.serviceDetails.clientLastName.id}
					labelText={T.serviceDetails.clientLastName.label}
					value={client.lastName}
					onChange={setClientAttribute}
					required
					$gridArea="input-2"
					error={errors?.lastName}
				/>
				<S.Input
					id={T.serviceDetails.clientMaidenName.id}
					labelText={T.serviceDetails.clientMaidenName.label}
					value={client.maidenName}
					onChange={setClientAttribute}
					$gridArea="input-3"
					error={errors?.maidenName}
				/>
				<S.Input
					id={T.serviceDetails.clientPhone.id}
					labelText={T.serviceDetails.clientPhone.label}
					value={client.phone}
					onChange={setClientAttribute}
					required
					$gridArea="input-4"
					error={errors?.phone}
				/>
				<S.Input
					id={T.serviceDetails.clientSecondaryPhone.id}
					labelText={T.serviceDetails.clientSecondaryPhone.label}
					value={client.secondaryPhone}
					onChange={setClientAttribute}
					$gridArea="input-5"
					error={errors?.secondaryPhone}
				/>
				<S.Input
					id={T.serviceDetails.clientEmail.id}
					labelText={T.serviceDetails.clientEmail.label}
					value={client.email}
					onChange={setClientAttribute}
					$gridArea="input-6"
					error={errors?.email}
				/>
			</S.InputsContainer>
			{[ATLAS_MEXICO_ID, AFIRME_MEXICO_ID].includes(user.account) && (
				<S.HighlightedInputsContainer>
					<S.HighlightedInput
						id={T.serviceDetails.clientDriverName.id}
						labelText={T.serviceDetails.clientDriverName.label}
						value={client.driverName}
						onChange={setClientAttribute}
						error={errors?.driverName}
					/>

					<S.BrandIcon>
						<img src={accountLogos[user.account]} />
					</S.BrandIcon>
				</S.HighlightedInputsContainer>
			)}
			{displayVehicleFields && (
				<>
					<S.Separator />
					<S.FormSectionTitle>{T.serviceDetails.sectionTitleVehicle}</S.FormSectionTitle>
					<S.InputsContainer
						className={
							[
								ATLAS_MEXICO_ID,
								CRABI_MEXICO_ID,
								CLUPP_MEXICO_ID,
								AFIRME_MEXICO_ID,
								...KAVAK_MEXICO_ACCOUNT_IDS,
							].includes(user.account)
								? 'pre-highlight'
								: undefined
						}
						$section="vehicle"
					>
						<S.Dropdown<string>
							id={T.serviceDetails.vehicleMake.id}
							label={T.serviceDetails.vehicleMake.label}
							onChange={(key, value) => {
								setVehicleAttribute(key, value);
								setVehicleModelsByMake(value as string);
							}}
							items={vehicleMakes}
							selectedItem={vehicle.make}
							required
							$gridArea="make"
							error={errors?.make}
						/>
						<S.Input
							id={T.serviceDetails.vehicleOtherMake.id}
							labelText={T.serviceDetails.vehicleOtherMake.label}
							value={vehicle.otherMake}
							onChange={setVehicleAttribute}
							required={vehicle.make === DEFAULT_VEHICLE_MAKE_MODEL}
							disabled={vehicle.make !== DEFAULT_VEHICLE_MAKE_MODEL}
							$gridArea="other-make"
							error={errors?.otherMake}
						/>
						<S.Dropdown<string>
							id={T.serviceDetails.vehicleModel.id}
							label={T.serviceDetails.vehicleModel.label}
							onChange={setVehicleAttribute}
							items={vehicleModels}
							selectedItem={vehicle.model}
							required
							$gridArea="model"
							error={errors?.model}
						/>
						<S.Input
							id={T.serviceDetails.vehicleOtherModel.id}
							labelText={T.serviceDetails.vehicleOtherModel.label}
							value={vehicle.otherModel}
							onChange={setVehicleAttribute}
							required={vehicle.model === DEFAULT_VEHICLE_MAKE_MODEL}
							disabled={vehicle.model !== DEFAULT_VEHICLE_MAKE_MODEL}
							$gridArea="other-model"
							error={errors?.otherModel}
						/>
						<S.Dropdown<string>
							id={T.serviceDetails.vehicleArmorLevel.id}
							label={T.serviceDetails.vehicleArmorLevel.label}
							onChange={setVehicleAttribute}
							items={armorLevelOptions}
							selectedItem={vehicle.armorLevel}
							$gridArea="armor-level"
							error={errors?.armorLevel}
						/>
						<S.Dropdown<number>
							id={T.serviceDetails.vehicleYear.id}
							label={T.serviceDetails.vehicleYear.label}
							onChange={setVehicleAttribute}
							items={yearOptions}
							selectedItem={vehicle.year}
							required
							$gridArea="year"
							error={errors?.year}
						/>
						<S.Input
							id={T.serviceDetails.vehicleColor.id}
							labelText={T.serviceDetails.vehicleColor.label}
							value={vehicle.color}
							onChange={setVehicleAttribute}
							$gridArea="color"
							error={errors?.color}
						/>
						<S.Input
							id={T.serviceDetails.vehiclePlate.id}
							labelText={T.serviceDetails.vehiclePlate.label}
							value={vehicle.plate}
							onChange={setVehicleAttribute}
							required={![KAVAK_MEXICO_ACCOUNT_IDS, AFIRME_MEXICO_ID].includes(user.account)}
							$gridArea="plate"
							error={errors?.plate}
						/>
						<S.Input
							id={T.serviceDetails.vehicleVin.id}
							labelText={T.serviceDetails.vehicleVin.label}
							value={vehicle.vin}
							onChange={setVehicleAttribute}
							required={AFIRME_MEXICO_ID.includes(user.account)}
							$gridArea="vin"
							error={errors?.vin}
						/>
					</S.InputsContainer>
				</>
			)}

			{[
				ATLAS_MEXICO_ID,
				CRABI_MEXICO_ID,
				CLUPP_MEXICO_ID,
				HDI_MEXICO_ID,
				AFIRME_MEXICO_ID,
				VANTI_ID,
				...KAVAK_MEXICO_ACCOUNT_IDS,
			].includes(user.account) && (
				<>
					<S.HighlightedInputsContainer className="highlight">
						{KAVAK_MEXICO_ACCOUNT_IDS.includes(user.account) && (
							<>
								<S.HighlightedInput
									id={T.serviceDetails.vehicleKavakStock.id}
									labelText={T.serviceDetails.vehicleKavakStock.label}
									value={vehicle.externalId}
									onChange={setVehicleAttribute}
									required
									error={errors?.externalId}
								/>
								<S.HighlightedInput
									id={T.serviceDetails.vehicleKavakId.id}
									labelText={T.serviceDetails.vehicleKavakId.label}
									value={vehicle.kavakId}
									onChange={setVehicleAttribute}
									required
									error={errors?.kavakId}
								/>
							</>
						)}
						{[ATLAS_MEXICO_ID, CRABI_MEXICO_ID, CLUPP_MEXICO_ID, HDI_MEXICO_ID, AFIRME_MEXICO_ID, VANTI_ID].includes(
							user.account
						) && (
							<>
								<S.HighlightedInput
									id={T.serviceDetails.vehiclePolicyNumber.id}
									labelText={T.serviceDetails.vehiclePolicyNumber.label}
									value={vehicle.policyNumber}
									onChange={setVehicleAttribute}
									required
									error={errors?.policyNumber}
								/>
								<S.HighlightedInput
									id={displayExternalIdLabel.id}
									labelText={displayExternalIdLabel.label}
									value={vehicle.externalId}
									onChange={setVehicleAttribute}
									required={![AFIRME_MEXICO_ID, VANTI_ID].includes(user.account)}
									error={errors?.externalId}
								/>
							</>
						)}
						{[VANTI_ID].includes(user.account) && (
							<>
								<S.HighlightedInput
									id={T.serviceDetails.ticketNumberVanti.id}
									labelText={T.serviceDetails.ticketNumberVanti.label}
									value={service.ticketNumber}
									onChange={setServiceAttribute}
									required
									error={errors?.ticketNumber}
								/>
							</>
						)}
						{[ATLAS_MEXICO_ID, CLUPP_MEXICO_ID, CRABI_MEXICO_ID, AFIRME_MEXICO_ID].includes(user.account) && (
							<>
								<S.Dropdown<string>
									id={T.serviceDetails.VehicleType.id}
									label={T.serviceDetails.VehicleType.label}
									onChange={setVehicleAttribute}
									items={vehicleTypeOptions}
									selectedItem={vehicle.type}
									required
									error={errors?.type}
									style={{ width: '30%' }}
								/>
							</>
						)}
					</S.HighlightedInputsContainer>
					<S.HighlightedInputsContainer className="highlight">
						<S.BrandIcon>
							<img src={accountLogos[user.account]} />
						</S.BrandIcon>
					</S.HighlightedInputsContainer>
				</>
			)}

			<S.Separator />
			<S.FormSectionTitle>{T.serviceDetails.sectionTitleService}</S.FormSectionTitle>
			<S.InputsContainer $section={serviceLayoutSection}>
				<S.Dropdown<ServiceType>
					id={T.serviceDetails.serviceSituation.id}
					label={T.serviceDetails.serviceSituation.label}
					onChange={setServiceAttribute}
					items={serviceTypes}
					itemToString={(serviceType: ServiceType) => getServiceTypeLabel(serviceType)}
					selectedItem={service.situation}
					required
					$gridArea="situation"
					error={errors?.situation}
				/>
				{displayVehicleFields && (
					<>
						<S.Input
							id={T.serviceDetails.serviceCarrierName.id}
							labelText={T.serviceDetails.serviceCarrierName.label}
							value={service.carrierName}
							onChange={setServiceAttribute}
							required={KAVAK_MEXICO_ACCOUNT_IDS.includes(user.account)}
							$gridArea="carrier-name"
							error={errors?.carrierName}
						/>
						<S.Input
							id={T.serviceDetails.serviceCarrierPhone.id}
							labelText={T.serviceDetails.serviceCarrierPhone.label}
							value={service.carrierPhone}
							onChange={setServiceAttribute}
							required={KAVAK_MEXICO_ACCOUNT_IDS.includes(user.account)}
							$gridArea="carrier-phone"
							error={errors?.carrierPhone}
						/>
						<div className="m-3">
							<CheckboxButton onChange={handleChangeVip} checked={service.vip} label="Account VIP" />
						</div>
						{KAVAK_MEXICO_ACCOUNT_IDS.includes(user.account) && (
							<>
								<S.Input
									id={T.serviceDetails.serviceReceiverName.id}
									labelText={T.serviceDetails.serviceReceiverName.label}
									value={service.receiverName}
									onChange={setServiceAttribute}
									required
									$gridArea="receiver-name"
									error={errors?.receiverName}
								/>
								<S.Input
									id={T.serviceDetails.serviceReceiverPhone.id}
									labelText={T.serviceDetails.serviceReceiverPhone.label}
									value={service.receiverPhone}
									onChange={setServiceAttribute}
									required
									$gridArea="receiver-phone"
									error={errors?.receiverPhone}
								/>
							</>
						)}
					</>
				)}

				{displayVantiHogarFields && (
					<>
						<S.Dropdown<string>
							id={T.serviceDetails.visitType.id}
							label={T.serviceDetails.visitType.label}
							onChange={setServiceAttribute}
							items={VISIT_TYPE_OPTIONS}
							selectedItem={service.visitType}
							required
							$gridArea="scheduling-type"
							error={errors?.visitType}
						/>
						<S.Dropdown<string>
							id={T.serviceDetails.noveltyType.id}
							label={T.serviceDetails.noveltyType.label}
							onChange={setServiceAttribute}
							items={['Si', 'No']}
							selectedItem={service.noveltyType}
							$gridArea="novelty-c4c"
						/>
						<S.Input
							id={T.serviceDetails.city.id}
							labelText={T.serviceDetails.city.label}
							value={service.city}
							onChange={setServiceAttribute}
							$gridArea="city"
							error={errors?.city}
						/>
						<S.Input
							id={T.serviceDetails.date.id}
							labelText={T.serviceDetails.date.label}
							value={service.date}
							onChange={setServiceAttribute}
							$gridArea="date"
							error={errors?.date}
						/>
						<S.Input
							id={T.serviceDetails.time.id}
							labelText={T.serviceDetails.time.label}
							value={service.time}
							onChange={setServiceAttribute}
							$gridArea="time"
							error={errors?.time}
						/>
					</>
				)}
				<S.TextArea
					id={T.serviceDetails.serviceComments.id}
					labelText={T.serviceDetails.serviceComments.label}
					value={service.comments}
					required={displayVantiHogarFields}
					onChange={setServiceAttribute}
					$gridArea="comments"
					error={errors?.comments}
				/>
			</S.InputsContainer>
			<S.Separator />
		</S.FormContainer>
	);
};

export default ServiceDetails;
