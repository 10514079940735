import { FunctionComponent, ReactNode } from 'react';

interface TableRowProps {
	onClick: (param?: any) => void;
	children: ReactNode;
}

const TableRow: FunctionComponent<TableRowProps> = ({ onClick, children }) => {
	return (
		<div className="table-row" onClick={onClick}>
			{children}
		</div>
	);
};

export default TableRow;
