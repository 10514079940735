import { Service } from '../../interfaces';
import { formatTimestampLabel } from '../Dashboard/Dashboard.utils';

export const setUpInfo = (services: Service[], setServices: (value: Service[]) => void): void => {
	let servicesArray: any[] = [];
	for (const service of services) {
		if (!service.trip) {
			service.trip = {};
		}
		service.trip.statusLabel = service.trip ? formatTimestampLabel(service.trip.status) : '';
		service.createDate = new Date(service.date).toLocaleString();
		service.dispatchedDateDay = service.createDate.split(',')[0];
		service.dispatchedDateTime = service.createDate.split(', ')[1];

		if (service.appointmentDate) {
			service.appointmentDate = new Date(service.appointmentDate).toLocaleString();
		}

		service.arrivedTime = '00:00';
		if (service.trip.live && service.trip.live.eta) {
			const date = new Date(+service.trip.live.eta * 1000);
			service.trip.live.eta = date.toISOString().substr(11, 5);
		} else {
			service.trip.live = {
				eta: '00:00',
			};
		}
		if (service?.trip?.stamps) {
			for (const stamp of service.trip.stamps) {
				if (stamp.status === 'on_route') {
					service.onRouteTime = new Date(Number(stamp.time)).toLocaleString().split(' ').splice(1, 2).join(' ');
				}
				if (stamp.status === 'arrived') {
					service.arrivedTime = new Date(Number(stamp.time)).toLocaleString().split(' ').splice(1, 2).join(' ');
				}
				if (stamp.status === 'towed') {
					service.towedTime = new Date(Number(stamp.time)).toLocaleString().split(' ').splice(1, 2).join(' ');
				}
				if (stamp.status === 'finished') {
					service.finishedTime = new Date(Number(stamp.time)).toLocaleString().split(' ').splice(1, 2).join(' ') as any;
				}
			}
		}

		servicesArray = [...servicesArray, service];
	}
	setServices(servicesArray);
};

export const validateEmail = (email: string): boolean => {
	const re =
		/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};
