import { useContext } from 'react';
import { Context } from '@context/Context';
import { AppContext } from '@interfaces';
import { ROLE } from '@utils/constants';
import { ALLOWED_FOR_ADMINS, FEATURE_FLAG } from '@utils/FeatureFlags/constants';

export default function useFeatureFlag(name: FEATURE_FLAG, accountId?: string): boolean {
	const { userType, provider, user, featureFlags } = useContext(Context) as AppContext;

	const isAdmin = user?.roles.some((role: string) => role === ROLE.ADMIN_EOS);
	const isAllowedForAdmin = isAdmin && ALLOWED_FOR_ADMINS.includes(name);

	const roles: string[] = user?.roles || provider?.roles || [];
	const branch = provider?.branch || user?.branch;

	// Use the user's account ID if none is provided
	if (!accountId && user?.account) {
		accountId = user.account;
	}

	// Find all feature flags that match the specified name
	const features = featureFlags?.filter((feature) => feature.name === name);

	const isFeatureEnabled = features?.some((feature) => {
		// Check if the feature is enabled for the user's type
		const hasValidUserType = !feature.userTypes?.length || feature.userTypes?.some((uType) => uType.name === userType);

		// Check if the feature is enabled for the user's branch
		const hasValidBranch = !feature.branches?.length || feature.branches?.some((b) => b.name === branch);

		// Check if the feature is enabled for the user's account
		const hasValidAccount =
			!feature.accounts?.length || feature.accounts?.some((account) => account.accountId === accountId);

		// Check if the feature is enabled for any of the user's roles
		const hasValidRole = !feature.roles?.length || feature.roles?.some((rol) => roles.includes(rol.name));

		// Feature is enabled for the user if all conditions are met and the feature itself is enabled
		const isFeatureEnabledForUser =
			hasValidUserType && hasValidBranch && hasValidAccount && hasValidRole && feature.enabled;

		// Return true if the feature is enabled for the user or if the user is an admin
		return isFeatureEnabledForUser || isAdmin;
	});

	// Final decision: feature is enabled if it's enabled for the user, and if the user is an admin, the feature must be allowed for admins
	return isFeatureEnabled && (!isAdmin || isAllowedForAdmin);
}
