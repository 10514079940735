import { EosService, Service, Situations, ServiceTypeCategoryToIcon, SituationsToIcon } from '@interfaces';
import OtherServices from '@assets/imgs/dashboard-icons/otherservices_icon_off.png';

export const getServiceIcon = (service?: Service | EosService): string => {
	const assets = require.context('@assets/imgs/dashboard-icons', true);

	if (service?.category) {
		const iconName = getServiceTypeIconName(service, assets);
		return iconName;
	} else if (SituationsToIcon.includes(String(service?.situation))) {
		let situationIcon = '';
		try {
			situationIcon = assets(`./${service?.situation}.svg`);
			return situationIcon;
		} catch (error) {
			return OtherServices;
		}
	}

	return OtherServices;
};

/**
 * Returns the icon name given a service type.
 * @returns the service type icon name.
 */
export const getServiceTypeIconName = (
	service: Service | EosService,
	assets: __WebpackModuleApi.RequireContext
): string => {
	if (
		service.virtualInspector &&
		service.virtualInspector === 'Yes' &&
		service.viClientAccepted &&
		service.viClientAccepted === 'Yes'
	) {
		return Situations.VIRTUAL_INSPECTIONS;
	} else {
		const iconName = ServiceTypeCategoryToIcon.get(String(service.category));
		return (iconName ? assets(`./${iconName}.svg`) : OtherServices) ?? OtherServices;
	}
};

export const evaluateInspectionLabel = (service: Service | EosService, type = 'helios'): string => {
	if (type === 'helios') {
		if (
			service.situation === Situations.INSPECTIONS &&
			service.virtualInspector === 'Yes' &&
			service.viClientAccepted === 'Yes'
		) {
			service.situation = Situations.VIRTUAL_INSPECTIONS;
		}
	} else {
		const vendors = ['v1114', 'v1113', 'v1112'];
		vendors.forEach((vendor: string) => {
			if (vendor == service.vendorId) {
				service.situation = Situations.VIRTUAL_INSPECTIONS;
			}
		});
	}

	return getServiceIcon(service);
};

export const getStatusLabelColor = (service: Service): string => {
	if (service.status === 'Cancelled') return 'declined';
	switch (service.trip.status) {
		case 'new':
			return 'new';
		case 'accepted':
			return 'accepted';
		case 'on_route':
			return 'on-route';
		case 'arrived':
			return 'arrived';
		case 'finished':
			return 'finished';
		case 'towed':
			return 'towed';
		case 'cancelled':
			return 'declined';
		case 'cancelled_by_driver':
			return 'cancelled-by-driver';
		case 'pending_audit':
			return 'finished';
		default:
			return 'new';
	}
};

export const getStatusLabel = (service: Service): string | undefined => {
	if (
		service.status === 'Cancelled' ||
		service.status === 'Finished' ||
		service.status === 'Not Covered' ||
		service.status === 'Informative'
	)
		return service.status;
	else if (service.trip && service.trip.statusLabel) return service.trip.statusLabel;
	return '';
};
