// External imports
import { useContext, useState } from 'react';
// Global imports
import { Context } from '@context/Context';
import { AppContext } from '@interfaces';
import HeliosClient from '@api/HeliosClient';
import { BRANCH } from '@utils/constants';
import T from '@views/CreateService/texts';
import { ClientContract } from '@api/interfaces';
import { DispatchFormContext } from '@views/CreateService/context/Context';
// Local imports
import { getVehicleMakeModel } from '../utils';
import { steps } from '../../../constants';
import { IUseClientContractReturnValue } from '../types';

const useClientContract = (searchValue: string): IUseClientContractReturnValue => {
	const { user } = useContext(Context) as AppContext;
	const { vehicleMakes, setContract, setContractAttributes, setSelectedStep } = useContext(DispatchFormContext);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const branch = user.branch as BRANCH;

	const fetchContracts = async () => {
		const account = user.account;

		setErrorMessage(null);

		// TODO: mover a validación del input y no de este custom hook
		if (!searchValue) {
			setErrorMessage('Por favor, ingrese un texto válido para buscar');
			return;
		}

		if (!account || !branch) {
			setErrorMessage(T.searchContract.errors.missingAccountOrBranch);
			return;
		}

		const getClientContractParams = {
			branch,
			search: searchValue,
			account,
			active: true,
		};

		try {
			setLoading(true);
			const {
				data: { data, status },
			} = await new HeliosClient().getClientContract(getClientContractParams);

			if (!status) {
				setErrorMessage(T.searchContract.errors.errorFetchingContracts);
				return;
			}

			if (data.length === 0) {
				setErrorMessage(T.searchContract.errors.noContractFound);
				return;
			}

			setNewContract(data[0]);
		} catch (error) {
			setErrorMessage(T.searchContract.errors.errorFetchingContracts);
		} finally {
			setLoading(false);
		}
	};

	const setNewContract = async (clientContract: ClientContract) => {
		const { make, otherMake, model, otherModel } = await getVehicleMakeModel(
			vehicleMakes,
			clientContract?.vehicle?.make,
			clientContract?.vehicle?.model,
			branch
		);
		const newClientContract = {
			name: clientContract.customer?.first_name || '',
			lastName: clientContract.customer?.last_name || '',
			phone: clientContract.customer?.phone_number1 || '',
			secondaryPhone: clientContract.customer?.phone_number2 || '',
			email: clientContract.customer?.email || '',
		};
		const year = parseInt(clientContract?.vehicle?.year as string, 10);
		const newVehicleContract = {
			policyNumber: clientContract.policy?.number || '',
			year: year && !Number.isNaN(year) ? year : undefined,
			color: clientContract?.vehicle?.color || '',
			plate: clientContract?.vehicle?.plate || '',
			make,
			otherMake,
			model,
			otherModel,
			vin: clientContract?.vehicle?.vin || '',
		};
		setContract(clientContract);
		setContractAttributes(newClientContract, newVehicleContract);
		setSelectedStep(steps.SERVICE_DETAILS);
	};

	const continueWithoutContract = () => {
		setSelectedStep(steps.SERVICE_DETAILS);
	};

	return { fetchContracts, loading, errorMessage, continueWithoutContract };
};

export { useClientContract };
