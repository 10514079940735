// External imports
import React, { useContext, useState } from 'react';
// Global imports
import T from '@views/CreateService/texts';
import { Context } from '@context/Context';
import { AppContext } from '@interfaces';
// Local imports
import { useClientContract } from './hooks/useClientContract';
import S from './styles';

const SearchContract = (): React.ReactElement => {
	const { user } = useContext(Context) as AppContext;
	const [searchValue, setSearchValue] = useState('');
	const { fetchContracts, loading, errorMessage, continueWithoutContract } = useClientContract(searchValue);

	return (
		<S.SearchContractContainer borderType="shadow-highlight">
			<S.SearchContractTooltip>{T.searchContract.searchContractTooltip}</S.SearchContractTooltip>
			<S.Input
				id="search-contract"
				labelText={user?.searchCriteria || T.searchContract.searchContractInputDefaultLabel}
				value={searchValue}
				onChange={(_: any, value: string) => setSearchValue(value)}
				error={errorMessage ? errorMessage : undefined}
				required
				disabled={loading}
			/>
			<S.Button loading={loading} onClick={fetchContracts}>
				{T.searchContract.searchContractBtn}
			</S.Button>
			<S.Button disabled={loading} onClick={continueWithoutContract} kind="tertiary" $last>
				{T.searchContract.skipSearchContractBtn}
			</S.Button>
		</S.SearchContractContainer>
	);
};

export default SearchContract;
