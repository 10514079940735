const defaultItemToString = <T extends Record<string, unknown>>(item: T): string => {
	const defaultLabel: keyof T = 'label';
	if (typeof item === 'string') {
		return item;
	}
	if (typeof item === 'number') {
		return `${item}`;
	}
	if (item && typeof item === 'object' && defaultLabel in item && typeof item[defaultLabel] === 'string') {
		return item[defaultLabel] as string;
	}
	return '';
};

const getClearItem = <T>(item: T, itemsToString: (item: T) => string, clearLabel: string): T | null => {
	const clearItem = JSON.parse(JSON.stringify(item));
	const itemString = itemsToString(clearItem);
	if (typeof item === 'string') {
		return clearLabel as unknown as T;
	} else if (item !== null && typeof item === 'object') {
		for (const key in clearItem) {
			if (Object.hasOwnProperty.call(clearItem, key) && clearItem[key] === itemString) {
				(clearItem[key] as string) = clearLabel;
			}
		}
		return clearItem as T;
	}
	return null;
};

export { defaultItemToString, getClearItem };
