import { FunctionComponent } from 'react';
import { USER_TYPE } from '@utils/constants';
import { ActiveService } from '@interfaces';
import { ActiveServiceItem } from './ActiveServiceItem';
import { Divider } from 'primereact/divider';

interface DriverInfoBoxProps {
	userType: string;
	selectedDriver: any;
	selectedServices: ActiveService[] | undefined;
	closeDriverInfo: () => void;
	getStampStatusName: (status: string) => string;
}

const DriverInfoBox: FunctionComponent<DriverInfoBoxProps> = ({
	userType,
	selectedDriver,
	selectedServices,
	closeDriverInfo,
	getStampStatusName,
}): JSX.Element => {
	return (
		<div className="driver-info">
			<div className="driver-info__header">
				<span>{userType === USER_TYPE.USER ? 'Services Information' : 'Driver Information'}</span>
				<span onClick={closeDriverInfo}>x</span>
			</div>
			<div className="driver-info__body">
				{userType !== USER_TYPE.USER && <div className="driver-info__driver-name">{selectedDriver.name}</div>}
				{userType !== USER_TYPE.USER && <div>{selectedDriver.provider.name}</div>}
				{!selectedServices?.length && (
					<div>
						<br />
						<p>No active services</p>
					</div>
				)}
				{selectedServices?.map((service: ActiveService, idx) => (
					<>
						<ActiveServiceItem
							key={service.serviceNumber}
							getStampStatusName={getStampStatusName}
							selectedDriver={selectedDriver}
							service={service}
							userType={userType}
						/>
						{idx < selectedServices.length - 1 && <Divider />}
					</>
				))}
			</div>
		</div>
	);
};

export default DriverInfoBox;
