import useFeatureFlag from '@hooks/useFeatureFlag';
import React from 'react';
import { FEATURE_FLAG } from './constants';

interface FeatureProps {
	name: FEATURE_FLAG;
	accountId?: string;
	children: React.ReactNode;
}

const Feature = ({ name, accountId, children }: FeatureProps) => {
	const isFeatureEnabled = useFeatureFlag(name, accountId);

	if (isFeatureEnabled) {
		return <>{children}</>;
	}

	return null;
};

export default Feature;
