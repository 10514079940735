// External imports
import React, { useContext } from 'react';
// Global imports

// Local imports
import S from './styles';
import T from '../../texts';
import { IProps } from './types';
import { steps } from '../../constants';
import { useServiceForm } from '../../hooks/useCreateService';
import { DispatchFormContext } from '@views/CreateService/context/Context';

const NavigationButtons = ({ currentStep, setCurrentStep }: IProps): React.ReactElement | null => {
	const { loading } = useContext(DispatchFormContext);
	const { validateServiceDetailsAndContinue, validateServiceLocationsAndSubmit } = useServiceForm();
	const previousStep = currentStep - 1;

	if (currentStep === steps.SEARCH_CONTRACT) {
		return null;
	}

	return (
		<S.NavigationButtonsContainer>
			<S.Button
				disabled={loading}
				kind="ghost"
				onClick={() => {
					setCurrentStep(previousStep);
				}}
			>
				{T.navigationButtons.back}
			</S.Button>
			{currentStep === steps.SERVICE_DETAILS && (
				<S.Button loading={loading} $last onClick={validateServiceDetailsAndContinue}>
					{T.navigationButtons.next}
				</S.Button>
			)}
			{currentStep === steps.SERVICE_LOCATIONS && (
				<S.Button loading={loading} $last onClick={validateServiceLocationsAndSubmit}>
					{T.navigationButtons.submit}
				</S.Button>
			)}
		</S.NavigationButtonsContainer>
	);
};

export default NavigationButtons;
