// External imports
import { object, string, number, setLocale, bool } from 'yup';
// Global imports
import {
	ATLAS_MEXICO_ID,
	CLUPP_MEXICO_ID,
	CRABI_MEXICO_ID,
	DEFAULT_VEHICLE_MAKE_MODEL,
	HDI_MEXICO_ID,
	KAVAK_MEXICO_ACCOUNT_IDS,
	AFIRME_MEXICO_ID,
	VANTI_ID,
} from '@utils/constants';
import { validatePhoneNumber } from '@utils/PhoneNumber.utils';
import { ValidationErrors } from '@assets/textx/errors';
import T from './texts';

setLocale({
	mixed: {
		required: ValidationErrors.required,
	},
	string: {
		email: ValidationErrors.invalidEmail,
	},
	number: {
		positive: ValidationErrors.invalidPositiveNumber,
		integer: ValidationErrors.invalidIntegerNumber,
	},
});

const clientSchema = object({
	name: string().required(),
	lastName: string().required(),
	maidenName: string(),
	phone: string()
		.required()
		.test('phone', ValidationErrors.invalidPhone, (value: string, props: any) => {
			const { branch } = props.options.context;
			return validatePhoneNumber(value, branch);
		}),
	secondaryPhone: string().test('secondaryPhone', ValidationErrors.invalidPhone, (value?: string, props?: any) => {
		if (!value) return true;
		const { branch } = props.options.context;
		return validatePhoneNumber(value, branch);
	}),
	email: string().email(),
	driverName: string(),
});

const vehicleSchema = object({
	make: string().when('$account', ([account], schema) => (account === VANTI_ID ? schema : schema.required())),
	otherMake: string().when(['make', '$account'], ([make, account], schema) =>
		make === DEFAULT_VEHICLE_MAKE_MODEL && account !== VANTI_ID ? schema.required() : schema
	),
	model: string().when('$account', ([account], schema) => (account === VANTI_ID ? schema : schema.required())),
	otherModel: string().when(['model', '$account'], ([model, account], schema) =>
		model === DEFAULT_VEHICLE_MAKE_MODEL && account !== VANTI_ID ? schema.required() : schema
	),
	armorLevel: string().nullable(),
	type: string().when('$account', ([account], schema) =>
		[ATLAS_MEXICO_ID, CLUPP_MEXICO_ID, CRABI_MEXICO_ID, AFIRME_MEXICO_ID].includes(account) ? schema.required() : schema
	),
	year: number()
		.when('$account', ([account], schema) => (account === VANTI_ID ? schema : schema.required()))
		.positive()
		.integer(),
	color: string(),
	plate: string().when('$account', ([account], schema) =>
		[KAVAK_MEXICO_ACCOUNT_IDS, AFIRME_MEXICO_ID, VANTI_ID].includes(account) ? schema : schema.required()
	),
	kavakId: string().when('$account', ([account], schema) =>
		KAVAK_MEXICO_ACCOUNT_IDS.includes(account) ? schema.required() : schema
	),
	policyNumber: string().when('$account', ([account], schema) =>
		[ATLAS_MEXICO_ID, CLUPP_MEXICO_ID, CRABI_MEXICO_ID, HDI_MEXICO_ID, AFIRME_MEXICO_ID].includes(account)
			? schema.required()
			: schema
	),
	externalId: string().when('$account', ([account], schema) =>
		[ATLAS_MEXICO_ID, CLUPP_MEXICO_ID, CRABI_MEXICO_ID, HDI_MEXICO_ID, ...KAVAK_MEXICO_ACCOUNT_IDS].includes(account)
			? schema.required()
			: schema
	),
	vin: string().when('$account', ([account], schema) =>
		AFIRME_MEXICO_ID.includes(account) ? schema.required() : schema
	),
});

const serviceSchema = object({
	situation: object({
		name: string().required(),
	}).required(),
	carrierName: string().when('$account', ([account], schema) =>
		KAVAK_MEXICO_ACCOUNT_IDS.includes(account) ? schema.required() : schema
	),
	carrierPhone: string()
		.when('$account', ([account], schema) => (KAVAK_MEXICO_ACCOUNT_IDS.includes(account) ? schema.required() : schema))
		.test('carrierPhone', ValidationErrors.invalidPhone, (value?: string, props?: any) => {
			if (!value) return true;
			const { branch } = props.options.context;
			return validatePhoneNumber(value, branch);
		}),
	receiverName: string().when('$account', ([account], schema) =>
		KAVAK_MEXICO_ACCOUNT_IDS.includes(account) ? schema.required() : schema
	),
	receiverPhone: string()
		.when('$account', ([account], schema) => (KAVAK_MEXICO_ACCOUNT_IDS.includes(account) ? schema.required() : schema))
		.test('receiverPhone', ValidationErrors.invalidPhone, (value?: string, props?: any) => {
			if (!value) return true;
			const { branch } = props.options.context;
			return validatePhoneNumber(value, branch);
		}),
	comments: string().when('$account', ([account], schema) => (VANTI_ID === account ? schema.required() : schema)),
	vip: bool(),
	visitType: string().when('$account', ([account], schema) => (VANTI_ID === account ? schema.required() : schema)),
	ticketNumber: string().when('$account', ([account], schema) =>
		VANTI_ID === account ? schema.required().matches(/^\d+$/, ValidationErrors.invalidIntegerNumber) : schema
	),
});

const serviceDetailsSchema = clientSchema.concat(vehicleSchema).concat(serviceSchema);

const locationSchemaDefinition = {
	lat: number().required(T.locations.errorMalformedLocation),
	lng: number().required(T.locations.errorMalformedLocation),
	address: string().required(T.locations.errorMalformedLocation),
	placeId: string().required(T.locations.errorMalformedLocation),
};

const serviceLocationsSchema = object({
	situation: object(locationSchemaDefinition).when('$account', ([account], schema) =>
		![ATLAS_MEXICO_ID, HDI_MEXICO_ID, AFIRME_MEXICO_ID, VANTI_ID].includes(account)
			? schema.required()
			: schema.nullable()
	),
	destination: object(locationSchemaDefinition).when(
		['$account', '$destinationRequired'],
		([account, destinationRequired], schema) =>
			![ATLAS_MEXICO_ID, HDI_MEXICO_ID, AFIRME_MEXICO_ID].includes(account) && destinationRequired
				? schema.required()
				: schema.nullable()
	),
	referenceAddress: string().when('$account', ([account], schema) =>
		account === VANTI_ID ? schema.required() : schema.nullable()
	),
});

export { serviceDetailsSchema, serviceLocationsSchema };
