import { FunctionComponent } from 'react';
import cn from 'classnames';

interface CustomInputProps {
	colSize: string;
	label: string;
	editMode?: boolean;
	children?: JSX.Element;
}

const CustomInput: FunctionComponent<CustomInputProps> = ({ colSize, label, editMode, children }): JSX.Element => {
	return (
		<div className={colSize}>
			<div className="input-group">
				<div className="input-group-prepend">
					<span className={cn('input-group-text', { 'input-group-text__edit-mode': editMode })}>{label}</span>
				</div>
				{children}
			</div>
		</div>
	);
};

export default CustomInput;
