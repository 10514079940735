import { FunctionComponent } from 'react';
import ImageGallery from 'react-image-gallery';

interface GalleryProps {
	images: any[];
}

const Gallery: FunctionComponent<GalleryProps> = ({ images }): JSX.Element => {
	const renderImageGallery = () => {
		const imgs = images
			// Remove PDF files
			.filter((image) => !(image.name.includes('.pdf') || image.url.includes('.pdf')))
			// map them to the format required by react-image-gallery
			.map((image) => ({
				original: image.url,
				thumbnail: image.url,
			}));
		return <ImageGallery showPlayButton={false} items={imgs} />;
	};
	return (
		<>
			{!images || images.length === 0 ? (
				<div className="col text-white mb-3">
					<div className="nothing-found-alert">No images found</div>
				</div>
			) : (
				renderImageGallery()
			)}
		</>
	);
};

export default Gallery;
