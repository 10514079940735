import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Providers from '@assets/imgs/tow.svg';
import Clients from '@assets/imgs/users.svg';
import './styles.scss';

const Login: FunctionComponent = (): JSX.Element => {
	return (
		<div className="login-page">
			<Link to="/login/providers" className="login-page__card">
				<h5>Providers Login</h5>
				<img src={Providers} className="login-page__card-tow" alt="providers button" />
			</Link>

			<Link to="/login/users" className="login-page__card">
				<h5>Clients Login</h5>
				<img src={Clients} className="login-page__card-user" alt="clients button" />
			</Link>

			<Link to="/login/admin" className="login-page__card">
				<h5>Admin Login</h5>
				<img src={Clients} className="login-page__card-user" alt="admin button" />
			</Link>
		</div>
	);
};

export default Login;
