import { ActiveService } from '@interfaces';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDateTime } from '@utils/Utils';
import { USER_TYPE } from '@utils/constants';
import { setTimeToDestination, setTimeToSituation } from '@views/MapView/MapView.utils';
import { formatServiceLabel } from '@views/Service/Service.util';

interface ActiveServiceItemProps {
	service: ActiveService;
	userType: string;
	getStampStatusName: (status: string) => string;
	selectedDriver: any;
}

interface Stamp {
	status: string;
}

export const ActiveServiceItem = ({
	service,
	userType,
	getStampStatusName,
	selectedDriver,
}: ActiveServiceItemProps) => {
	const [ttd, setTtd] = useState<string | null>();

	useEffect(() => {
		getTtd(service, selectedDriver);
	}, [service, selectedDriver]);

	const events = useMemo(
		() => service?.trip?.stamps.filter((stamp: Stamp) => ['accepted', 'on_route'].includes(stamp.status)),
		[]
	);
	const tts = useMemo(() => setTimeToSituation(service), [service.trip.live]);

	const getTtd = async (service: ActiveService, driver: any) => {
		const result = await setTimeToDestination(service, driver);
		setTtd(result);
	};

	return (
		<>
			{service && (
				<div>
					{userType !== USER_TYPE.USER && <br />}
					<b>Service Type:</b> {formatServiceLabel(service?.situation)}
				</div>
			)}
			{service.locations.situation.address && (
				<div>
					<br />
					<b>Situation Address:</b> <br />
					{service.locations.situation.address}
				</div>
			)}
			{service.serviceNumber && (
				<div>
					<br />
					<b>PO: </b>
					<Link to={`/services/${service.serviceNumber}`}>#{service.serviceNumber}</Link>
				</div>
			)}
			{tts && (
				<div>
					<b>TTS:</b> {tts}
				</div>
			)}
			{service.locations.destination.address && (
				<div>
					<br />
					<b>Destination Address:</b> <br />
					{service.locations.destination.address}
				</div>
			)}
			{ttd && (
				<div>
					<b>TTD:</b> {ttd}
				</div>
			)}

			{events?.length && (
				<div>
					<br />
					<b>Events</b>
					<br />
					{events.map((stamp: any) => (
						<React.Fragment key={stamp.status}>
							{getStampStatusName(stamp.status)} {formatDateTime(stamp.time)}
							<br />
						</React.Fragment>
					))}
				</div>
			)}
		</>
	);
};
