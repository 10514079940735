import { FunctionComponent, useContext } from 'react';

import { Context } from '@context/Context';
import { AppContext } from '@interfaces';
import { setCurrentPage, setStatusFilter } from '@context/actions';
import { DASHBOARD_SECTION } from '@utils/constants';
import Feature from '@utils/FeatureFlags';
import { FEATURE_FLAG } from '@utils/FeatureFlags/constants';

import ActiveOn from '@assets/imgs/active_on.png';
import ActiveOff from '@assets/imgs/active_off.png';
import ScheduledOn from '@assets/imgs/inbox_icon_on.svg';
import ScheduledOff from '@assets/imgs/inbox_icon_off.svg';
import CancelledOn from '@assets/imgs/cancelled_on.png';
import CancelledOff from '@assets/imgs/cancelled_off.png';
import FinishedOn from '@assets/imgs/finished_on.png';
import FinishedOff from '@assets/imgs/finished_off.png';
import BillingOn from '@assets/imgs/billing-on.svg';
import BillingOff from '@assets/imgs/billing-off.svg';
import InvoiceOn from '@assets/imgs/invoice-on.svg';
import InvoiceOff from '@assets/imgs/invoice-off.svg';
import OtherStatusOn from '@assets/imgs/other_status_on.svg';
import OtherStatusOff from '@assets/imgs/other_status_off.svg';

interface DashboardHeaderProps {
	status: string;
	clearSearch?: any;
}

const DashboardHeader: FunctionComponent<DashboardHeaderProps> = ({ status, clearSearch }): JSX.Element => {
	const { dispatch } = useContext(Context) as AppContext;
	const handleOnClick = (statusFilter: string): void => {
		clearSearch();
		setStatusFilter(dispatch, statusFilter);
		setCurrentPage(dispatch, 0);
	};

	return (
		<div className="dashboard__header">
			<h1>{status === DASHBOARD_SECTION.HOLD_INSPECTION ? 'Service Q' : status}</h1>
			<div className="dashboard__status-filters">
				<Feature name={FEATURE_FLAG.DASHBOARD_ACTIVE}>
					<img
						onClick={(): void => {
							handleOnClick(DASHBOARD_SECTION.ACTIVE);
						}}
						src={status === DASHBOARD_SECTION.ACTIVE ? ActiveOn : ActiveOff}
					/>
				</Feature>
				<Feature name={FEATURE_FLAG.DASHBOARD_CANCELLED}>
					<img
						onClick={(): void => {
							handleOnClick(DASHBOARD_SECTION.CANCELLED);
						}}
						src={status === DASHBOARD_SECTION.CANCELLED ? CancelledOn : CancelledOff}
					/>
				</Feature>
				<Feature name={FEATURE_FLAG.DASHBOARD_SCHEDULED}>
					<img
						onClick={(): void => {
							handleOnClick(DASHBOARD_SECTION.HOLD_INSPECTION);
						}}
						src={status === DASHBOARD_SECTION.HOLD_INSPECTION ? ScheduledOn : ScheduledOff}
					/>
				</Feature>
				<Feature name={FEATURE_FLAG.DASHBOARD_FINISHED}>
					<img
						onClick={(): void => {
							handleOnClick(DASHBOARD_SECTION.FINISHED);
						}}
						src={status === DASHBOARD_SECTION.FINISHED ? FinishedOn : FinishedOff}
					/>
				</Feature>
				<Feature name={FEATURE_FLAG.DASHBOARD_OTHERSTATUS}>
					<img
						onClick={(): void => handleOnClick(DASHBOARD_SECTION.OTHER_STATUS)}
						src={status === DASHBOARD_SECTION.OTHER_STATUS ? OtherStatusOn : OtherStatusOff}
					/>
				</Feature>
				<Feature name={FEATURE_FLAG.DASHBOARD_BILLING}>
					<img
						onClick={(): void => handleOnClick(DASHBOARD_SECTION.BILLING)}
						src={status === DASHBOARD_SECTION.BILLING ? BillingOn : BillingOff}
					/>
				</Feature>
				<Feature name={FEATURE_FLAG.DASHBOARD_INVOICE}>
					<img
						onClick={(): void => handleOnClick(DASHBOARD_SECTION.INVOICE)}
						src={status === DASHBOARD_SECTION.INVOICE ? InvoiceOn : InvoiceOff}
					/>
				</Feature>
			</div>
		</div>
	);
};

export default DashboardHeader;
