import {
	AFIRME_MEXICO_ID,
	ATLAS_MEXICO_ID,
	CLUPP_MEXICO_ID,
	CRABI_MEXICO_ID,
	HDI_MEXICO_ID,
	KAVAK_GUADALAJARA_MEXICO_ID,
	KAVAK_MEXICO_ID,
	KAVAK_MONTERREY_MEXICO_ID,
	KAVAK_PUEBLA_MEXICO_ID,
	KAVAK_QUERETARO_MEXICO_ID,
} from '@utils/constants';

import logoKavak from '@assets/imgs/client-logo-kavak.svg';
import logoAtlas from '@assets/imgs/client-logo-atlas.svg';
import logoClupp from '@assets/imgs/client-logo-clupp.png';
import logoCrabi from '@assets/imgs/client-logo-crabi.svg';
import logoHdiMx from '@assets/imgs/client-hdi-mx-logo.svg';
import logoAfirme from '@assets/imgs/client-logo-afirme.svg';

export const accountLogos: Record<string, string> = {
	[KAVAK_MEXICO_ID]: logoKavak,
	[KAVAK_PUEBLA_MEXICO_ID]: logoKavak,
	[KAVAK_GUADALAJARA_MEXICO_ID]: logoKavak,
	[KAVAK_MONTERREY_MEXICO_ID]: logoKavak,
	[KAVAK_QUERETARO_MEXICO_ID]: logoKavak,
	[ATLAS_MEXICO_ID]: logoAtlas,
	[CLUPP_MEXICO_ID]: logoClupp,
	[CRABI_MEXICO_ID]: logoCrabi,
	[HDI_MEXICO_ID]: logoHdiMx,
	[AFIRME_MEXICO_ID]: logoAfirme,
};
