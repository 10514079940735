import { Heading, Text } from '@connect-assistance/connect-ui';
import { formatTimestampToCountryTz } from '@utils/datetime.utils';
import { Fragment, useMemo } from 'react';
import { ProviderQuoteStatusLabels, ProviderQuoteStatus } from '../utils';
import styles from './QuoteAccordionContent.module.css';
import { ContentHeaderProps, ContentNoteProps, QuoteAccordionContentProps, QuoteItemsContentProps } from './types';

const ContentHeader = ({ name, status, creatorName, timestamp, branch }: ContentHeaderProps) => (
	<div className={styles.quoteHeader}>
		<Heading appearance="xs">{`${name} (${ProviderQuoteStatusLabels[status]})`}</Heading>
		<Text size="sm" appearance="italic">
			{(status !== ProviderQuoteStatus.PendingReview ? `${creatorName} - ` : '') +
				formatTimestampToCountryTz(timestamp, branch, 'MMMM D, YYYY, h:mm:ssa')}
		</Text>
	</div>
);

const ContentNote = ({ note }: ContentNoteProps) => (
	<div className={styles.quoteNote}>
		<Text appearance="bold" elementType="span">
			Note:
		</Text>
		<Text elementType="span">{note}</Text>
	</div>
);

const QuoteItemsContent = ({ description, items, total }: QuoteItemsContentProps) => {
	const formatter = useMemo(() => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }), []);
	return (
		<>
			<div className={styles.quoteDescription}>
				<Text elementType="span">{description}</Text>
			</div>
			<div className={styles.quoteItems}>
				{/* Table Headers */}
				<span className={`${styles.quoteItemDescription} ${styles.quoteItemsHeader}`}>Quote Items</span>
				<span className={styles.quoteItemsHeader}>Qty</span>
				<span className={styles.quoteItemsHeader}>Unit price</span>
				<span className={styles.quoteItemsHeader}>Total price</span>
				{/* Table Rows */}
				{items.map(({ description: itemDescription, qty, unitPrice, total }) => {
					const itemKey = `${description}-${itemDescription}-${qty}-${unitPrice}-${total}`;
					return (
						<Fragment key={itemKey}>
							<span className={`${styles.quoteItemDescription} ${styles.quoteItemElement}`}>{itemDescription}</span>
							<span className={styles.quoteItemElement}>{qty}</span>
							<span className={styles.quoteItemElement}>{formatter.format(unitPrice / 100)}</span>
							<span className={styles.quoteItemElement}>{formatter.format(total / 100)}</span>
						</Fragment>
					);
				})}
			</div>
			<div className={styles.quoteTotal}>Total Quote {formatter.format(total / 100)}</div>
		</>
	);
};

export function QuoteAccordionContent({ quoteLog, branch }: QuoteAccordionContentProps): JSX.Element {
	return (
		<div className={styles.quoteDetails}>
			<ContentHeader
				name={quoteLog.name}
				creatorName={quoteLog.creatorName}
				status={quoteLog.status}
				timestamp={quoteLog.timestamp}
				branch={branch}
			/>
			{quoteLog.note && <ContentNote note={quoteLog.note} />}
			{quoteLog.status === 'PENDING_REVIEW' && (
				<QuoteItemsContent description={quoteLog.description} items={quoteLog.items} total={quoteLog.total} />
			)}
		</div>
	);
}
