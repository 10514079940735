import { FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router';

import { Context } from '@context/Context';
import { AppContext } from '@interfaces';
import TableRow from './TableRow';
import { Service } from '@interfaces';
import { USER_TYPE } from '@utils/constants';
import { getServiceIcon, getStatusLabel, getStatusLabelColor } from './Table.utils';
import { getSituationTooltip } from '@utils/Utils';

interface OtherStatusTableProps {
	services?: Service[];
}

const OtherStatusTable: FunctionComponent<OtherStatusTableProps> = ({ services }) => {
	const ACTION_COLUMNS = {
		ACTION_COLUMN: 'action_column',
	};
	const history = useHistory();
	const { userType, situationTypes } = useContext(Context) as AppContext;
	const goToService = (event: any, serviceNumber: number | undefined): void => {
		if (shouldRedirect(event)) return;
		history.push(`/services/${serviceNumber}`);
	};

	const shouldRedirect = (event: any): boolean => {
		return Object.values(ACTION_COLUMNS).some(
			(actionColumn) =>
				event.target.classList?.contains(actionColumn) ||
				event.target.closest(`.${actionColumn}`)?.classList?.contains(actionColumn)
		);
	};

	return (
		<div className="eos-table eos-table--hover">
			<div className="table-head">
				<div className="table-row">
					<div className="eos-table__header">Service #</div>
					<div className="eos-table__header">Situation</div>
					<div className="eos-table__header">Status</div>
					<div className="eos-table__header">Service Source</div>
					<div className="eos-table__header eos-table__header__column-w-15">Dispatched Date/Time</div>
					<div className="eos-table__header">Account</div>
					{userType === USER_TYPE.USER && <div className="eos-table__header">Policy ID</div>}
				</div>
			</div>
			{services?.slice(0, 51).map((service: Service) => (
				<TableRow onClick={(event): void => goToService(event, service.serviceNumber)} key={service._id}>
					<div data-label="Service Number" className="table-cell">
						{service.serviceNumber}
					</div>
					<div data-label="Situation" className="table-cell">
						<div className="eos-table__tooltip">
							<img className="eos-table__icon" src={getServiceIcon(service)} />
							<span className="eos-table__tooltiptext">{getSituationTooltip(service.situation, situationTypes)}</span>
						</div>
					</div>
					<div data-label="Status" className="table-cell">
						<div className={`eos-table__btn eos-table__btn--lg ${getStatusLabelColor(service)}`}>
							{getStatusLabel(service)}
						</div>
					</div>
					<div data-label="Service Source" className="table-cell">
						{service.serviceSource}
					</div>
					<div data-label="Dispatched Date/Time" className="table-cell">
						{service.createDate}
					</div>
					<div data-label="Account" className="table-cell">
						{service.account}
					</div>
					{userType === USER_TYPE.USER && (
						<div data-label="Policy Number" className="table-cell">
							{service.policyNumber}
						</div>
					)}
				</TableRow>
			))}
		</div>
	);
};

export default OtherStatusTable;
