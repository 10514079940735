export const ProviderQuoteStatus = {
	PendingReview: 'PENDING_REVIEW',
	ReviewInProgress: 'REVIEW_IN_PROGRESS',
	Approved: 'APPROVED',
	Returned: 'RETURNED',
} as const;

/**
 * A mapping of internal provider quote statuses to user-friendly display labels.
 */
export const ProviderQuoteStatusLabels = {
	[ProviderQuoteStatus.PendingReview]: 'Pending',
	[ProviderQuoteStatus.ReviewInProgress]: 'In Review',
	[ProviderQuoteStatus.Approved]: 'Approved',
	[ProviderQuoteStatus.Returned]: 'Returned',
} as const;

/**
 * A mapping of internal provider quote statuses to user-friendly display labels for service details view
 */
export const QuoteStatusLabelsServiceDetails = {
	[ProviderQuoteStatus.PendingReview]: 'Pending',
	[ProviderQuoteStatus.ReviewInProgress]: 'Review',
	[ProviderQuoteStatus.Approved]: 'Approved',
	[ProviderQuoteStatus.Returned]: 'Returned',
} as const;
