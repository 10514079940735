import HeliosClient from '@api/HeliosClient';
import { useEffect, useState } from 'react';
import { ServiceProviderQuoteHistory } from '../types';

export default function useProviderQuoteHistory(serviceId: string): {
	quoteHistory: ServiceProviderQuoteHistory[];
	isLoading: boolean;
	error: Error | undefined;
} {
	const [quoteHistory, setQuoteHistory] = useState<ServiceProviderQuoteHistory[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<Error | undefined>(undefined);

	useEffect(() => {
		let ignore = false;
		setIsLoading(true);

		new HeliosClient()
			.getServiceProviderQuotesLogs(serviceId)
			.then((res) => {
				if (!res.data.status || !res.status) {
					throw new Error('Failed to fetch provider quotes');
				}
				if (!ignore) {
					setQuoteHistory(res.data.data);
					setError(undefined);
				}
			})
			.catch((err) => {
				if (!ignore) {
					setError(err);
					setQuoteHistory([]);
				}
			})
			.finally(() => {
				if (!ignore) {
					setIsLoading(false);
				}
			});

		return () => {
			ignore = true;
		};
	}, [serviceId]);

	return { quoteHistory, isLoading, error };
}
