// External imports
import Axios, { AxiosResponse, AxiosStatic } from 'axios';
import localforage from 'localforage';
// Local imports
import { Service, Params } from '../interfaces';
import { HELIOS_API, ROLE, USER_TYPE } from '../utils/constants';
import { parseObjToQuery } from '../utils/Utils';
import HttpClient from './HttpClient';
import { storeAccountAuth, storeProviderAuth, storeAuth, transformSearchCriteria } from './HeliosClient.utils';
import type {
	DispatchServiceResponse,
	GetClientContractData,
	GetClientContractResponse,
	ServiceRequestPostParams,
	GetPoliciesResponse,
	GetBranchConfig,
	ICreateProviderQuotePayload,
	ICreateProviderQuoteResponse,
	IGetServiceProviderQuotesLogsResponse,
	IGetServiceProviderQuotesResponse,
	IUpdateProviderQuotePayload,
	IUpdateServiceProviderQuoteResponse,
} from './interfaces';
export default class HeliosClient extends HttpClient {
	constructor(axios: AxiosStatic = Axios) {
		super(axios, HELIOS_API);
	}

	async getServices(params: Params): Promise<AxiosResponse> {
		const prefix = params.sfId ? 'accounts' : 'providers';
		return await this.get(`/${prefix}/services/${parseObjToQuery(params)}`);
	}

	async searchServices(params: Params): Promise<AxiosResponse> {
		const prefix = params.sfId ? 'accounts' : 'providers';
		return await this.get(`/${prefix}/services/search${parseObjToQuery(params)}`);
	}

	async getServiceById(id: string): Promise<AxiosResponse> {
		const user: any = await localforage.getItem(USER_TYPE.USER);
		let url: string;

		if (user) {
			const adminRole = user.roles.find((role: string) => role === ROLE.ADMIN_EOS);
			if (adminRole) {
				url = `/services/${id}`;
			} else url = `/accounts/services/${id}/?account=${user.account}`;
		} else url = `/providers/services/${id}`;

		return await this.get(url);
	}

	async getByDateRange(userType: string, body: any): Promise<AxiosResponse> {
		const prefix = userType === USER_TYPE.USER ? 'accounts' : 'providers';
		return await this.get(`/${prefix}/reports/${parseObjToQuery(body)}`);
	}

	async clientLogin(data: any): Promise<AxiosResponse> {
		const response: any = await this.post('/users/login', data);

		if (response.data.status && response.data.data.user.hasSecurePassword) {
			const responseData = response.data.data;
			if (responseData.user.account) {
				responseData.user.searchCriteria = transformSearchCriteria(responseData.user.account?.config?.searchCriteria);
				responseData.user.fixedLocations = responseData.user.account?.fixedLocations;
				responseData.user.otherServicesAllowed = responseData.user.account?.otherServicesAllowed;
				responseData.user.homeServicesAllowed = responseData.user.account?.homeServicesAllowed;
				responseData.user.account = responseData.user.account.salesforce;
			}
			storeAuth(responseData.token.token, responseData.token.expires);
			storeAccountAuth(responseData.user);
		}
		return response;
	}

	async providerLogin(data: any): Promise<any> {
		const response: any = await this.post('/providers/login', data);

		if (response.data.status) {
			storeAuth(response.data.data.token.token, response.data.data.token.expires);
			storeProviderAuth(response.data.data.provider);
		}
		return response;
	}

	async forgotPassword(userType: string, data: any): Promise<AxiosResponse> {
		return await this.post(`/${userType}/reset`, data);
	}

	async resetPassword(userType: string, data: any, token: string): Promise<AxiosResponse> {
		return await this.post(`/${userType}/resetPassword/${token}`, data);
	}

	async getTypes(): Promise<AxiosResponse> {
		return this.get('/services/types/all');
	}

	async getProviders(): Promise<AxiosResponse> {
		return this.get('/providers/all');
	}

	async toggleAjusteExpress(data: any, userType: string): Promise<AxiosResponse> {
		const prefix = userType === USER_TYPE.USER ? 'accounts' : 'providers';
		return await this.patch(`/${prefix}/services/ajusteExpress/toggle`, data);
	}

	async markAsRead(serviceId: string, fromFlag: string): Promise<AxiosResponse> {
		return await this.patch(`/services/${serviceId}/notes`, { fromFlag });
	}

	async getTTD(body: any): Promise<AxiosResponse> {
		return this.post('/maps/directions/trafficTtd', body);
	}

	async addNote(data: any): Promise<AxiosResponse> {
		const endpointPrefix = data.userType === USER_TYPE.USER ? '/accounts/services' : '/providers/services';
		return await this.post(`${endpointPrefix}/addNote`, { ...data });
	}

	async getActiveDrivers(branch: string): Promise<AxiosResponse> {
		return await this.get(`/api/v1/drivers/?branch=${branch}`);
	}

	async getStaticDrivers(branch: string): Promise<AxiosResponse> {
		return await this.get(`/api/v1/static-drivers/?branch=${branch}`);
	}

	async getActiveServices(data: { [key: string]: string }): Promise<AxiosResponse> {
		return await this.get(`/api/v1/drivers/active-services/${parseObjToQuery(data)}`);
	}

	async getDriverLocation(driverId: string): Promise<AxiosResponse> {
		return await this.get(`/providers/drivers/driverById/${driverId}/location`);
	}

	async addServiceTimestamp(data: any): Promise<AxiosResponse> {
		return await this.post(`/service/trip/${data._id}/timestamp?dashboard=true`, data);
	}

	async markAsConfirmed(serviceId: string): Promise<AxiosResponse> {
		return await this.put(`/providers/services/${serviceId}/confirm`);
	}

	async finish(service: Service): Promise<AxiosResponse> {
		return await this.post('/providers/services/finish', service);
	}

	async getDriversByProviderId(providerId: string): Promise<AxiosResponse> {
		return await this.get(`/providers/${providerId}/drivers?basicDriverProjection=true`);
	}

	async updateService({
		serviceId,
		data,
	}: {
		serviceId: string;
		data: { serviceCost?: number; schedule?: string; driverId?: string };
	}): Promise<AxiosResponse> {
		return await this.put(`/providers/services/${serviceId}`, data);
	}

	async getClientContract(data: GetClientContractData): Promise<AxiosResponse<GetClientContractResponse>> {
		return await this.get(`/api/v1/dispatch/contracts${parseObjToQuery(data)}`);
	}

	async dispatchService(data: ServiceRequestPostParams): Promise<AxiosResponse<DispatchServiceResponse>> {
		return await this.post('/api/v1/service-requests', data);
	}

	async getPolicyIdentifiersOfAccount(salesforceId: string): Promise<AxiosResponse<GetPoliciesResponse>> {
		return await this.get(`/accounts/${salesforceId}/sf-policies`);
	}

	async getBranchConfigs(branch: string): Promise<AxiosResponse<GetBranchConfig>> {
		const queryParams = new URLSearchParams({ branch });
		return await this.get(`/api/v2/branch-configs/?${queryParams.toString()}`);
	}

	async createProviderQuote(
		serviceId: string,
		data: ICreateProviderQuotePayload
	): Promise<AxiosResponse<ICreateProviderQuoteResponse, any>> {
		return await this.post<ICreateProviderQuoteResponse, ICreateProviderQuotePayload>(
			`/api/v1/services/${serviceId}/providerQuotes`,
			data
		);
	}

	async getServiceProviderQuotesLogs(
		serviceId: string
	): Promise<AxiosResponse<IGetServiceProviderQuotesLogsResponse, any>> {
		return await this.get<IGetServiceProviderQuotesLogsResponse>(`/api/v1/services/${serviceId}/providerQuotes/logs`);
	}

	async getServiceProviderQuotes(serviceId: string): Promise<AxiosResponse<IGetServiceProviderQuotesResponse, any>> {
		return await this.get<IGetServiceProviderQuotesResponse>(`/api/v1/services/${serviceId}/providerQuotes`);
	}

	async updateServiceProviderQuote(
		serviceId: string,
		providerQuoteId: string,
		data: IUpdateProviderQuotePayload
	): Promise<AxiosResponse<IUpdateServiceProviderQuoteResponse, any>> {
		return await this.patch<IUpdateServiceProviderQuoteResponse>(
			`/api/v1/services/${serviceId}/providerQuotes/${providerQuoteId}`,
			data
		);
	}
}
