import styled from 'styled-components';
import BorderedContainer from '@components/Commons/BorderedContainer';
import BaseButton from '@components/Commons/Button';
// Global import
import BaseInput from '@components/Commons/Input';

interface IStypedProps {
	$last?: boolean;
}

const SearchContractContainer = styled(BorderedContainer)`
	width: 50%;
	min-width: 700px;
	padding: 56px 44px 72px 44px;
	@media only screen and (max-width: 1056px) {
		width: 100%;
		min-width: unset;
		padding: 18px 23px 28px 23px;
	}
`;

const SearchContractTooltip = styled.p`
	width: 300px;
	color: ${({ theme }) => theme.colors.textoGrisPrimario};
	${({ theme }) => theme.typography.p2}
`;

const Input = styled(BaseInput)`
	width: 300px;
	margin-bottom: 39px;
`;

const Button = styled(BaseButton)<IStypedProps>`
	margin-top: 10px;
	width: ${({ $last }) => ($last ? '250px' : '200px')};
	margin-right: ${({ $last }) => ($last ? '0' : '21px')};
`;

const LoadingContainer = styled.div`
	margin-right: 5px;
`;

const S = { SearchContractContainer, SearchContractTooltip, Input, Button, LoadingContainer };

export default S;
