import { FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router';
import Toggle from 'react-toggle';
import cn from 'classnames';

import { Context } from '@context/Context';
import { AppContext, EOSActions, Service, User } from '@interfaces';
import TableRow from './TableRow';
import { USER_TYPE, DASHBOARD_SECTION, SURA_ID, QUALITAS_ID, ProviderQuoteStatusAlertTag } from '@utils/constants';
import { getServiceIcon, getStatusLabel, getStatusLabelColor } from './Table.utils';
import { canConfirmThisService, distanceFormat } from '@views/Service/Service.util';
import HeliosClient from '@api/HeliosClient';
import ConfirmAction from './components/ConfirmAction';
import { getSituationTooltip } from '@utils/Utils';
import { formatDateTime } from '@utils/datetime.utils';

import NoteBubble from '@assets/imgs/chat-bubble.png';
import AlertTag from '@components/DesignSystem/AlertTag';
import Feature from '@utils/FeatureFlags';
import { FEATURE_FLAG } from '@utils/FeatureFlags/constants';
import { ProviderQuoteStatus } from '@views/Service/components/ProviderQuotes/utils';

interface ServicesTableProps {
	services?: Service[];
	dashboardSection: string;
	userAccount: User;
}

const ServicesTable: FunctionComponent<ServicesTableProps> = ({ services, dashboardSection, userAccount }) => {
	const ACTION_COLUMNS = {
		ACTION_COLUMN: 'action_column',
	};
	const history = useHistory();
	const { userType, provider, situationTypes } = useContext(Context) as AppContext;
	const goToService = (event: any, serviceNumber: number | undefined): void => {
		if (shouldRedirect(event)) return;
		history.push(`/services/${serviceNumber}`);
	};

	const PROVIDER_HAS_MARK_AS_CONFIRM_ACCESS =
		provider?.eosActions?.some((action) => action.action === EOSActions.MARK_AS_CONFIRM) || false;

	const shouldRedirect = (event: any): boolean => {
		return Object.values(ACTION_COLUMNS).some(
			(actionColumn) =>
				event.target.classList?.contains(actionColumn) ||
				event.target.closest(`.${actionColumn}`)?.classList?.contains(actionColumn)
		);
	};

	const onClickConfirmAction = async (serviceId: string): Promise<{ status: boolean; message: string }> => {
		try {
			const { data } = await new HeliosClient().markAsConfirmed(serviceId);
			if (data.status) return { status: true, message: data.message };
			else return { status: false, message: data.message };
		} catch (error) {
			return { status: false, message: 'Something went wrong, please try again' };
		}
	};

	return (
		<div className="eos-table eos-table--hover">
			<div className="table-head">
				<div className="table-row">
					<div className="eos-table__header">Service #</div>
					<div className="eos-table__header">Situation</div>
					{dashboardSection !== DASHBOARD_SECTION.HOLD_INSPECTION && <div className="eos-table__header">Status</div>}
					<Feature name={FEATURE_FLAG.PROVIDER_QUOTES}>
						{dashboardSection === DASHBOARD_SECTION.ACTIVE && <div className="eos-table__header">Quote status</div>}
					</Feature>
					<div className="eos-table__header">Service Source</div>
					{dashboardSection === DASHBOARD_SECTION.ACTIVE && <div className="eos-table__header">Elapsed Time</div>}
					<div className="eos-table__header eos-table__header__column-w-15">
						{dashboardSection === DASHBOARD_SECTION.HOLD_INSPECTION ? ' Scheduled ' : ' Dispatched '} Date/Time
					</div>
					{userType === USER_TYPE.PROVIDER && dashboardSection !== DASHBOARD_SECTION.HOLD_INSPECTION && (
						<div className="eos-table__header">Scheduled Date/Time</div>
					)}
					{userType === USER_TYPE.PROVIDER && <div className="eos-table__header">Driver / Store</div>}
					<div className="eos-table__header">Account</div>
					{userType === USER_TYPE.USER && <div className="eos-table__header">Policy ID</div>}
					<div className="eos-table__header">City</div>
					<div className="eos-table__header">Plate</div>
					<div className="eos-table__header">Start Address</div>
					<div className="eos-table__header">Finish Address</div>
					{userType === USER_TYPE.PROVIDER && <div className="eos-table__header">PS Distance</div>}
					{userType === USER_TYPE.PROVIDER && <div className="eos-table__header">SD Distance</div>}
					<div className="eos-table__header">Arrived Time</div>
					<div className="eos-table__header">Towed Time</div>
					<div className="eos-table__header">Arrival Duration</div>
					{dashboardSection === DASHBOARD_SECTION.CANCELLED && (
						<div className="eos-table__header">Cancellation Reason</div>
					)}
					{userAccount?.account === SURA_ID && <div className="eos-table__header">Ajuste Express</div>}
					{dashboardSection === DASHBOARD_SECTION.HOLD_INSPECTION && (
						<div className="eos-table__header eos-table__header__column-w-7">Actions</div>
					)}
				</div>
			</div>
			{services?.slice(0, 51).map((service: Service) => (
				<TableRow onClick={(event): void => goToService(event, service.serviceNumber)} key={service._id}>
					<div data-label="Service Number" className="table-cell">
						{service.serviceNumber}
					</div>
					<div data-label="Situation" className="table-cell">
						<div className="eos-table__tooltip">
							<img className="eos-table__icon" src={getServiceIcon(service)} />
							<span className="eos-table__tooltiptext">{getSituationTooltip(service.situation, situationTypes)}</span>
						</div>
					</div>
					{dashboardSection !== DASHBOARD_SECTION.HOLD_INSPECTION && (
						<div data-label="Status" className="table-cell">
							<div className={`eos-table__btn eos-table__btn--lg ${getStatusLabelColor(service)}`}>
								{getStatusLabel(service)}
							</div>
						</div>
					)}
					<Feature name={FEATURE_FLAG.PROVIDER_QUOTES}>
						{dashboardSection === DASHBOARD_SECTION.ACTIVE && (
							<div data-label="Quote status" className="table-cell">
								{service.lastQuote?.status && (
									<AlertTag
										text={service.lastQuote?.status ? ProviderQuoteStatusAlertTag[service.lastQuote.status] : ''}
										variant={
											service.lastQuote?.status
												? service.lastQuote.status === ProviderQuoteStatus.Approved
													? 'success'
													: 'warning'
												: 'warning'
										}
										customClass="w-auto"
									/>
								)}
							</div>
						)}
					</Feature>
					<div data-label="Service Source" className="table-cell">
						{service.serviceSource}
					</div>
					{dashboardSection === DASHBOARD_SECTION.ACTIVE && (
						<div data-label="Elapsed Time" className="table-cell">
							<div className="eos-table__btn">{service.elap}</div>
						</div>
					)}
					{dashboardSection === DASHBOARD_SECTION.HOLD_INSPECTION ? (
						<div data-label="Scheduled Date" className="table-cell">
							{service?.schedule ? formatDateTime({ date: new Date(service.schedule) }) : 'N/A'}
						</div>
					) : (
						<div data-label="Dispatched Date" className="table-cell">
							{service.createDate}
						</div>
					)}
					{userType === USER_TYPE.PROVIDER && dashboardSection !== DASHBOARD_SECTION.HOLD_INSPECTION && (
						<div data-label="Scheduled Date" className="table-cell">
							{service.scheduleDate}
						</div>
					)}
					{userType === USER_TYPE.PROVIDER && (
						<div data-label="Driver" className="table-cell">
							{service.driver && service.driver.name ? service.driver.name : service.driverName || 'N/A'}
						</div>
					)}
					<div data-label="Account" className="table-cell">
						{service.account}
					</div>
					{userType === USER_TYPE.USER && (
						<div data-label="Policy Number" className="table-cell">
							{service.policyNumber}
						</div>
					)}
					<div data-label="City" className="table-cell">
						{service.serviceCity}
					</div>
					<div data-label="Plate" className="table-cell">
						{service.plate}
					</div>
					<div data-label="Start Address" className="table-cell">
						{service.pinStartRoadAddress || service.pinSituationAddress}
					</div>
					<div data-label="Finish Address" className="table-cell">
						{service.pinFinishRoadAddress || 'N/A'}
					</div>
					{userType === USER_TYPE.PROVIDER && (
						<div data-label="PS Distance" className="table-cell">
							{distanceFormat(service.branch, service.providerDistance)}
						</div>
					)}
					{userType === USER_TYPE.PROVIDER && (
						<div data-label="SD Distance" className="table-cell">
							{distanceFormat(service.branch, service.distance)}
						</div>
					)}
					<div data-label="Arrived Time" className="table-cell">
						{service.arrivedTime}
					</div>
					<div data-label="Towed Time" className="table-cell">
						{service.towedTime}
					</div>
					<div data-label="Arrival Duration" className="table-cell">
						{service.arrivalDuration}
					</div>
					{dashboardSection === DASHBOARD_SECTION.CANCELLED && (
						<div data-label="Cancellation Reason" className="table-cell">
							{service.cancellationReason}
						</div>
					)}
					{dashboardSection !== DASHBOARD_SECTION.HOLD_INSPECTION && userAccount?.account === SURA_ID && (
						<div data-label="Ajuste Express" className="table-cell">
							<Toggle
								icons={false}
								disabled={true}
								defaultChecked={service.external && service.external.ajusteExpress}
							/>
						</div>
					)}
					{dashboardSection !== DASHBOARD_SECTION.HOLD_INSPECTION &&
						userType === USER_TYPE.USER &&
						[SURA_ID, QUALITAS_ID].includes(userAccount?.account as string) && (
							<div data-label="Has Unread Notes" className="table-cell eos-table__chat-column">
								<img className="eos-table__chat-bubble" src={NoteBubble} />
								<div className={cn({ 'eos-table__chat-alert': service.hasUnreadNotes })} />
							</div>
						)}
					{dashboardSection === DASHBOARD_SECTION.HOLD_INSPECTION && (
						<div data-label="Actions" className={`table-cell ${ACTION_COLUMNS.ACTION_COLUMN}`}>
							<ConfirmAction
								confirmValue={canConfirmThisService(service?.warnings)}
								onClickAction={() => onClickConfirmAction(service._id as string)}
								disabled={!PROVIDER_HAS_MARK_AS_CONFIRM_ACCESS}
							/>
						</div>
					)}
				</TableRow>
			))}
		</div>
	);
};

export default ServicesTable;
