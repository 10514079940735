import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface SkeletonProps {
	amount: number;
}

const CellSkeleton: FunctionComponent<SkeletonProps> = ({ amount }): JSX.Element => {
	return (
		<div className="dashboard__cell-container">
			<Skeleton className="dashboard__cell-skeleton" count={amount} />
		</div>
	);
};

export default CellSkeleton;
