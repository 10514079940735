import { FunctionComponent, useEffect, useState } from 'react';
import { Divider } from 'primereact/divider';
import dayjs from 'dayjs';
import { ContactAttemptProps } from './types';
import { Title, FlexCenterItems, List, Time, Container, ListItem } from './styles';
import { IContactAttempts } from '@interfaces';

const ContactAttempts: FunctionComponent<ContactAttemptProps> = ({ service }): JSX.Element => {
	const [serviceContactAttempt, setContactAttempts] = useState<IContactAttempts[]>([]);

	useEffect(() => {
		if (service.contactAttempts !== undefined && service.contactAttempts.length > 0) {
			setContactAttempts(service.contactAttempts);
		}
	}, [service]);

	return (
		<div>
			{/* @ts-expect-error - primereact type bug */}
			<Container>
				<FlexCenterItems>
					<Title>Contact Attempts</Title>
				</FlexCenterItems>
				<Divider />
				<div>
					{serviceContactAttempt.map((contact: IContactAttempts, index: number) => (
						<List key={index}>
							<ListItem>
								<label className="text-bold">{contact.contactedBy}</label>
							</ListItem>
							<ListItem>
								<label className="text-bold">No Answer {index + 1} </label>
							</ListItem>
							<ListItem>
								<Time>{dayjs(contact.contactedAt).format('MMMM D, YYYY, h:mm:ss a')}</Time>
							</ListItem>
						</List>
					))}
				</div>
			</Container>
		</div>
	);
};

export default ContactAttempts;
