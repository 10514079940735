// External imports
import React, { useEffect } from 'react';
// @ts-expect-error: @carbon/react doesn't have full typescript support yet
import { ProgressStep } from '@carbon/react';
// Global imports
import { steps } from '@views/CreateService/constants';
import { useServiceForm } from '@views/CreateService/hooks/useCreateService';
import S from '@views/CreateService/styles';
import T from '@views/CreateService/texts';
// Local imports
import SearchContract from '../SearchContract';
import ServiceDetails from '../ServiceDetails';
import ServiceLocations from '../ServiceLocations';
import NavigationButtons from '../NavigationButtons';

const MainContent = (): React.ReactElement => {
	const { currentStep, setSelectedStep, initAccountServiceTypes, initVehicleMakes } = useServiceForm();

	useEffect(() => {
		initAccountServiceTypes();
		initVehicleMakes();
	}, []);

	return (
		<>
			<S.MainTitle>{T.createServiceMainTitle}</S.MainTitle>
			<S.NavigationContainer>
				<S.ProgressIndicator currentIndex={currentStep} spaceEqually>
					{T.createFormSteps.map((step) => (
						<ProgressStep key={step.label} label={step.label} secondaryLabel={step.secondaryLabel} />
					))}
				</S.ProgressIndicator>
				<NavigationButtons currentStep={currentStep} setCurrentStep={setSelectedStep} />
			</S.NavigationContainer>
			<S.StepContainer $activeStep={currentStep === steps.SEARCH_CONTRACT}>
				<SearchContract />
			</S.StepContainer>
			<S.StepContainer $activeStep={currentStep === steps.SERVICE_DETAILS}>
				<ServiceDetails />
			</S.StepContainer>
			<S.StepContainer $activeStep={currentStep === steps.SERVICE_LOCATIONS}>
				<ServiceLocations />
			</S.StepContainer>
		</>
	);
};

export default MainContent;
