import { ProviderQuoteStatus } from '@views/Service/components/ProviderQuotes/utils';

export const HELIOS_REGULAR_KEY = process.env.REACT_APP_HELIOS_REGULAR_KEY || '';
export const HELIOS_API = process.env.REACT_APP_HELIOS_API || '';
export const EOS_API = process.env.REACT_APP_EOS_API || '';
export const COMMON_API = process.env.REACT_APP_COMMON_API || '';
export const PENDO_APIKEY = process.env.REACT_APP_PENDO_APIKEY || '';
/** Connect Services URL **/
export const CONNECT_SERVICES_API = process.env.REACT_APP_CONNECT_SERVICES || '';
/** Connect Services Token **/
export const CONNECT_SERVICES_TOKEN = process.env.REACT_APP_CONNECT_SERVICES_TOKEN || '';

/** Inspection Link **/
export const INSPECTION_LINK = process.env.REACT_APP_INSPECTION_LINK || '';

/** Max file size to upload in bytes **/
export const MAX_FILE_SIZE_IN_BYTES = parseInt(process.env.REACT_APP_MAX_FILE_SIZE_IN_BYTES || '1000000');

/** Max quantity of files to upload in Invoice */
export const MAX_QUANTITY_FILES = parseInt(process.env.REACT_APP_MAX_QUANTITY_FILES || '3');

export const SURA_ID = '0011Y00002noEzWQAU';
export const QUALITAS_ID = '0011Y00002c1UyJQAU';
export const CSM_INSPECTIONS = '0011Y00002knIpGQAU';
export const HDI_MASCOTAS_Y_GENERALES_ID = 'hdi-mascotas-y-generales';
export const SURAMERICANA = '0014z00001frqAoAAI';
export const VANTI = '0014z00001hHaFWAA0';

export const KAVAK_MEXICO_ID = 'kavak---mexico';
export const KAVAK_PUEBLA_MEXICO_ID = 'kavak---puebla';
export const KAVAK_GUADALAJARA_MEXICO_ID = 'kavak---guadalajara';
export const KAVAK_MONTERREY_MEXICO_ID = 'kavak---monterrey';
export const KAVAK_QUERETARO_MEXICO_ID = 'kavak---queretaro';

export const KAVAK_MEXICO_ACCOUNT_IDS = [
	KAVAK_MEXICO_ID,
	KAVAK_PUEBLA_MEXICO_ID,
	KAVAK_GUADALAJARA_MEXICO_ID,
	KAVAK_MONTERREY_MEXICO_ID,
	KAVAK_QUERETARO_MEXICO_ID,
];

export const ATLAS_MEXICO_ID = 'seguros-atlas';
export const CLUPP_MEXICO_ID = 'clupp';
export const CRABI_MEXICO_ID = 'crabi';
export const HDI_MEXICO_ID = 'hdi';
export const AFIRME_MEXICO_ID = 'afirme';
export const VANTI_ID = '0014z00001hHaFWAA0';

/** AWS Lambda to manage S3 files */
export const S3_MANAGER_SERVICE_URL = process.env.REACT_APP_S3_MANAGER_SERVICE_URL || '';
export const S3_MANAGER_SERVICE_API_KEY = process.env.REACT_APP_S3_MANAGER_SERVICE_API_KEY || '';

/** Bucket used on Invoice Attachments - Lambda S3 Manager Service */
export const S3_EOS_BUCKET = process.env.REACT_APP_S3_EOS_BUCKET || 'cra-eos-staging';
export const S3_EOS_BUCKET_REGION = process.env.REACT_APP_S3_EOS_BUCKET_REGION || 'us-east-1';

/** Bucket used in PO Gallery - Connect Services */
export const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || 'devinspecciones';
export const S3_BUCKET_REGION = process.env.REACT_APP_S3_BUCKET_REGION || 'us-east-1';

/** Folder name in S3 to save Invoice Attachments */
export const INVOICE_ATTACHMENTS = 'invoice-attachments';

/** Max quantity of files to upload in Service Gallery */
export const GALLERY_MAX_QUANTITY_FILES = parseInt(process.env.REACT_APP_GALLERY_MAX_QUANTITY_FILES || '10');

export const DASHBOARD_SECTION = {
	ACTIVE: 'Active',
	CANCELLED: 'Cancelled',
	HOLD_INSPECTION: 'HoldInspection',
	FINISHED: 'Finished',
	BILLING: 'Billing',
	INVOICE: 'Invoice',
	OTHER_STATUS: 'Other Status',
};

export enum HELIOS_SERVICE_STATUS {
	ACTIVE = 'Active',
	FINISHED = 'Finished',
	HOLD_INSPECTION = 'HoldInspection',
	QUEUED = 'Queued',
	CANCELLED = 'Cancelled',
}

export enum USER_TYPE {
	USER = 'user',
	PROVIDER = 'provider',
}

export enum ROLE_TYPE {
	ADMIN = 'admin',
	CLIENT = 'client',
	PROVIDER = 'provider',
}

export const ROLE = {
	ADMIN_EOS: 'admin_eos',
};

export const EOS_STATUS = {
	APPROVED: 'Approved',
	PENDING: 'Pending',
	REJECTED: 'Rejected',
	VALIDATED: 'Validated',
	TOPAYMENT: 'toPayment',
};

export enum FILE_TYPE {
	PDF = 'application/pdf',
	XML = 'application/xml',
	TEXT_XML = 'text/xml',
	JPEG = 'image/jpeg',
}

export enum searchType {
	EOS = 'EOS',
	HELIOS = 'HELIOS',
}

export enum BRANCH {
	PRI = 'Puerto Rico',
	PAN = 'Panama',
	CRC = 'Costa Rica',
	COL = 'Colombia',
	MEX = 'Mexico',
}

export const BRANCH_CODES: { [key: string]: string } = {
	'Puerto Rico': 'PRI',
	Panama: 'PAN',
	'Costa Rica': 'CRC',
	Colombia: 'COL',
	Mexico: 'MEX',
};

export const EOS_STATUSES = ['Pending', 'Validated', 'Approved', 'Paid', 'Rejected'];
export const EOS_OTHER_STATUSES = ['Not Covered', 'Informative'];
export const bytesToMegabytes = (bytes: number = MAX_FILE_SIZE_IN_BYTES): string => {
	return (bytes / 1000000).toFixed();
};

export const isTow = (situation: string): boolean => {
	return [
		'towCollision',
		'towBreakdown',
		'towTire',
		'towExtraction',
		'grua_transporte',
		'wheelLift',
		'towHeavyWeight',
		'garage_to_garage',
		'desplazamiento',
	].includes(situation);
};

export enum HOUR_FORMAT {
	TWELVE_HOUT_FORMAT = '12',
	TWENTY_FOUR_HOUR_FORMAT = '24',
}

export enum WARNING_TYPES {
	NOT_CONFIRMED = 'Not Confirmed',
}

/** Billing drawer */
export const DRAWER_DIRECTION_DEFAULT_STATE = 'up__85';
export const DRAWER_DIRECTION_OPENED = 'up__0';

export const ONE_MILE_IN_METERS = 1609.344;

export const enum VEHICLE_TYPES {
	AUTO = 1,
	MOTO = 2,
	AUTOMOTO = 3,
}

export const DEFAULT_VEHICLE_MAKE_MODEL = 'Otro';

export const VISIT_TYPE_OPTIONS = ['Programación', 'Reprogramación', 'Cancelación', 'Garantía', 'Incumplimiento'];

export enum Alpha2Code {
	CO = 'CO',
	CR = 'CR',
	MX = 'MX',
	PA = 'PA',
	PR = 'PR',
}

type BranchAlpha2CodeMap = {
	[key in BRANCH]: Alpha2Code;
};

export const branchAlpha2CodeMap: BranchAlpha2CodeMap = {
	[BRANCH.PRI]: Alpha2Code.PR,
	[BRANCH.PAN]: Alpha2Code.PA,
	[BRANCH.CRC]: Alpha2Code.CR,
	[BRANCH.COL]: Alpha2Code.CO,
	[BRANCH.MEX]: Alpha2Code.MX,
};

export const SERVICE_SOURCE = 'eos-client-dispatch';
export const SERVICE_METADATA_SOURCE_DATA = { type: 'eos_dispatch' } as const;

export const HELIOS_STATUS = {
	HOLD_INSPECTION: 'HoldInspection',
};

export enum Timezones {
	'Puerto Rico' = 'America/Puerto_Rico',
	'Costa Rica' = 'America/Costa_Rica',
	'Panama' = 'America/Panama',
	'Colombia' = 'America/Bogota',
	'Mexico' = 'America/Mexico_City',
	'Global' = 'Etc/UTC',
}

export const DASHBOARDS_DATE_FORMAT = 'DD/MM/YYYY';

export const ProviderQuoteStatusAlertTag = {
	[ProviderQuoteStatus.PendingReview]: 'Pending',
	[ProviderQuoteStatus.ReviewInProgress]: 'Review',
	[ProviderQuoteStatus.Returned]: 'Returned',
	[ProviderQuoteStatus.Approved]: 'Approved',
};
