import { FunctionComponent } from 'react';

import { Service } from '../../interfaces';

import './styles.scss';

interface ReportTableProps {
	services: Service[];
	reportTableProperties: any;
}

const ReportTable: FunctionComponent<ReportTableProps> = ({ services, reportTableProperties }): JSX.Element => {
	return (
		<table className="report-table">
			<thead className="report-table__header">
				<tr>
					{reportTableProperties?.map((prop: any, index: number) => {
						return (
							<th key={index} hidden={Boolean(prop.hiddenColumn)}>
								{prop?.showHeader ? prop.header : ''}
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody>
				{services?.map((service: any, serviceIndex: number) => (
					<tr key={serviceIndex}>
						{reportTableProperties?.map((prop: any, propIndex: number) => (
							<td key={propIndex} hidden={Boolean(prop.hiddenColumn)}>
								{prop.value({ service, serviceIndex })}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default ReportTable;
