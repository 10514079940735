import axios from 'axios';
import { FunctionComponent } from 'react';
import '@views/Service/styles.scss';
import ICFile from '@assets/imgs/dnd/ic-file.svg';

interface IFile {
	url: string;
	name: string;
	created: number | Date;
	type: string;
}
interface FilesProps {
	files: IFile[];
}

const getDocumentDownload = async (url: string, name: string, type: string): Promise<boolean> => {
	try {
		const resp: any = await axios({
			url,
			method: 'GET',
			responseType: 'blob' as 'json',
			headers: {
				Accept: type,
			},
		});
		const blob = new Blob([resp], {
			type: `${type};charset=utf-8`,
		});
		saveAs(blob, name);
		return true;
	} catch (error) {
		return false;
	}
};
const Files: FunctionComponent<FilesProps> = ({ files }): JSX.Element => {
	const renderImageFiles = () => {
		if (files && files.length > 0) {
			return (
				<div className="files-list">
					{files.map((file: IFile, index: number) => (
						<div
							className="single-file"
							key={index}
							onClick={(): any => getDocumentDownload(file.url, file.name, file.type)}
						>
							<img src={ICFile} width="45px" alt="file" />
							<div className="info">
								<h4 className="name">
									{index + 1} {file.name}
								</h4>
								<p className="size">{file.type}</p>
							</div>
						</div>
					))}
				</div>
			);
		} else {
			return (
				<div>
					<span>Not found files uploaded</span>
				</div>
			);
		}
	};
	return (
		<>
			{!files || files.length == 0 ? (
				<div>
					<span>Not found files uploaded</span>
				</div>
			) : (
				renderImageFiles()
			)}
		</>
	);
};

export default Files;
