import { FunctionComponent } from 'react';

import BillingTable from './BillingTable';
import InvoiceTable from './InvoiceTable';
import OtherStatusTable from './OtherStatusTable';
import ServicesTable from './ServicesTable';
import { EosService, Invoice, Service, User } from '@interfaces';

import './styles.scss';
import { DASHBOARD_SECTION } from '@utils/constants';
import CellSkeleton from '../CellSkeleton';
interface TableContainerProps {
	services?: Service[];
	eosServices?: EosService[];
	eosInvoices?: Invoice[];
	status: string;
	userAccount: User;
	adminRole?: string;
	showRejectServiceButtons: boolean;
	onRejectServiceClick: (service: EosService) => void;
	paging: boolean;
	changingView: boolean;
}

const TableContainer: FunctionComponent<TableContainerProps> = ({
	services,
	eosServices,
	eosInvoices,
	status,
	userAccount,
	adminRole,
	showRejectServiceButtons,
	onRejectServiceClick,
	paging,
	changingView,
}): JSX.Element => {
	return paging || changingView ? (
		<CellSkeleton amount={51} />
	) : (
		<>
			<div className="eos-table eos-table--hover">
				{status === DASHBOARD_SECTION.BILLING ? (
					<BillingTable
						eosServices={eosServices}
						showRejectServiceButtons={showRejectServiceButtons}
						adminRole={adminRole}
						onRejectServiceClick={onRejectServiceClick}
					/>
				) : status === DASHBOARD_SECTION.INVOICE ? (
					<InvoiceTable eosInvoices={eosInvoices} />
				) : status === DASHBOARD_SECTION.OTHER_STATUS ? (
					<OtherStatusTable services={services} />
				) : (
					<ServicesTable services={services} dashboardSection={status} userAccount={userAccount} />
				)}
			</div>
		</>
	);
};

export default TableContainer;
