import HeliosClient from '@api/HeliosClient';
import useFeatureFlag from '@hooks/useFeatureFlag';
import { USER_TYPE } from '@utils/constants';
import { FEATURE_FLAG } from '@utils/FeatureFlags/constants';
import { useEffect, useState } from 'react';
import type { ProviderQuote } from '../types';

export default function useProviderQuote(serviceId: string, userType: USER_TYPE) {
	const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.PROVIDER_QUOTES);
	const [providerQuotes, setProviderQuotes] = useState<ProviderQuote[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<Error | undefined>(undefined);

	useEffect(() => {
		if (!isFeatureEnabled) {
			setIsLoading(false);
			return;
		}
		let ignore = false;
		setIsLoading(true);

		new HeliosClient()
			.getServiceProviderQuotes(serviceId)
			.then((res) => {
				if (!res.data.status || !res.status) {
					throw new Error('Failed to fetch provider quotes');
				}
				if (!ignore) {
					const sortedData = res.data.data.sort(
						(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
					);
					setProviderQuotes(sortedData);
					setError(undefined);
				}
			})
			.catch((err) => {
				if (!ignore) {
					setError(err);
					setProviderQuotes([]);
				}
			})
			.finally(() => {
				if (!ignore) {
					setIsLoading(false);
				}
			});

		return () => {
			ignore = true;
		};
	}, [isFeatureEnabled, serviceId, userType]);

	return { providerQuotes, setProviderQuotes, isLoading, error };
}
