// Deprecated components lib, should be replaced in te future
import 'primereact/resources/themes/mdc-dark-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// Carbon is the base default system for connect
import './carbon-connect-theme.scss';
import { ToastContainer } from 'react-toastify';

import { BrowserRouter as Router } from 'react-router-dom';
import ViewsRoot from './views/Root';
import AppState from '@context/Context';
import { ThemeProvider } from 'styled-components';
import { styledComponentsTheme } from '@assets/styled-components-theme';

const App = (): JSX.Element => {
	return (
		<AppState>
			<Router>
				<ThemeProvider theme={styledComponentsTheme}>
					<ToastContainer />
					<ViewsRoot />
				</ThemeProvider>
			</Router>
		</AppState>
	);
};

export default App;
