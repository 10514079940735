import { ActionType, AppContext, Action } from '../interfaces';
import { cleanState } from './Context';

export const reducer = (state: AppContext, action: Action): AppContext => {
	switch (action.type) {
		case ActionType.SET_STATUS_FILTER:
			return { ...state, status: action.data };
		case ActionType.SET_SELECTED_SITUATIONS:
			return { ...state, selectedSituations: action.data };
		case ActionType.SET_SELECTED_DIVISIONS:
			return { ...state, selectedDivisions: action.data };
		case ActionType.SET_SELECTED_PROVIDERS:
			return { ...state, selectedProviders: action.data };
		case ActionType.SET_SELECTED_SOCIAL_REASON:
			return { ...state, selectedSocialReason: action.data };
		case ActionType.SET_SEARCHTYPE:
			return { ...state, searchType: action.data };
		case ActionType.SET_SEARCHTERM:
			return { ...state, searchTerm: action.data };
		case ActionType.SET_CURRENT_PAGE:
			return { ...state, currentPage: action.data };
		case ActionType.SET_USER_TYPE:
			return { ...state, userType: action.data };
		case ActionType.SET_PROVIDER:
			return { ...state, provider: action.data };
		case ActionType.SET_USER:
			return { ...state, user: action.data };
		case ActionType.SET_SHOW_PREORDER_DRAWER:
			return { ...state, showPreOrderDrawer: action.data };
		case ActionType.SET_PREORDER_SERVICES:
			return { ...state, selectedEosServices: action.data };
		case ActionType.SET_SELECTED_INVOICES:
			return { ...state, selectedEosInvoices: action.data };
		case ActionType.SET_EOS_STATUS:
			return { ...state, selectedEosStatus: action.data };
		case ActionType.SET_EOS_OTHER_STATUS:
			return { ...state, selectedEosOtherStatus: action.data };
		case ActionType.LOGOUT:
			return { ...(cleanState as any), dispatch: state.dispatch };
		case ActionType.SET_SHOW_REJECT_PO_DRAWER:
			return { ...state, showRejectPODrawer: action.data };
		case ActionType.SET_FEATURE_FLAGS:
			return { ...state, featureFlags: action.data };
		case ActionType.SET_SITUATION_TYPES:
			return { ...state, situationTypes: action.data };
		case ActionType.SET_POLICIES:
			return { ...state, selectedPolicies: action.data };
		case ActionType.SET_PROVIDER_QUOTE_STATUS:
			return { ...state, selectedProviderQuoteStatus: action.data };
		default:
			return state;
	}
};
