import { FunctionComponent } from 'react';
import { InputNumber, InputNumberChangeParams } from 'primereact/inputnumber';

import { BRANCH } from '@utils/constants';
import { getBranchCurrencyAndLocale, transformToCents, transformToDecimals } from '@utils/Utils';

interface CurrencyInputProps {
	value?: number;
	branch: string | BRANCH;
	onChange: (value: number) => void;
	editMode?: boolean;
	className?: string;
	valueInCents?: boolean;
}

const CurrencyInput: FunctionComponent<CurrencyInputProps> = ({
	value = 0,
	onChange,
	editMode = false,
	branch,
	className = '',
	valueInCents,
}): JSX.Element => {
	const handleChange = (e: InputNumberChangeParams) => {
		if (e.value) {
			const value = valueInCents ? transformToCents(e.value) : e.value;
			onChange(value);
		}
	};
	const { currency, locale } = getBranchCurrencyAndLocale(branch as BRANCH);

	return (
		<InputNumber
			value={valueInCents ? transformToDecimals(value) : value}
			onChange={editMode ? handleChange : undefined}
			maxFractionDigits={2}
			minFractionDigits={2}
			className={className}
			mode="currency"
			currency={currency}
			locale={locale}
			readOnly={!editMode}
			disabled={!editMode}
		/>
	);
};

export default CurrencyInput;
