import { FunctionComponent, useContext, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import map from 'lodash/map';
import { saveAs } from 'file-saver';

import {
	AppContext,
	CreateEosInvoiceParams,
	FileReferences,
	PreOrderService,
	ProviderRetentionBreakdown,
	ProviderRetentionNames,
} from '../../interfaces';
import Button from '@components/DesignSystem/Button';
import { Context } from '../../context/Context';
import { BRANCH, DRAWER_DIRECTION_DEFAULT_STATE, DRAWER_DIRECTION_OPENED } from '../../utils/constants';
import {
	centsToCurrency,
	downloadFile,
	getIVALabel,
	getProviderRetentionBreakdown,
	getRetentionsByBranch,
} from '../../utils/Utils';
import EosClient from '../../api/EosClient';
import Table from './components/Table';
import { pdfIsRequired, uploadFile, xmlIsRequired } from '../Commons/FileUploader.utils';
import Attachments from './components/Attachments';
import './BillingPreOrder.scss';
import { FEATURE_FLAG } from '../../utils/FeatureFlags/constants';
import Feature from '../../utils/FeatureFlags';
import { ReactComponent as IconDownload } from '../../assets/design-system/icon-download.svg';

const getProviderPaymentTotalSum = (selectedEosServices: PreOrderService[]) => {
	return Object.values(selectedEosServices).reduce((acc, curr) => acc + (curr?.service?.providerPaymentTotal || 0), 0);
};

const getProviderPaymentTaxSum = (selectedEosServices: PreOrderService[]) => {
	return Object.values(selectedEosServices).reduce((acc, curr) => acc + (curr?.service?.providerPaymentTax || 0), 0);
};

const getProviderPaymentCostSum = (selectedEosServices: PreOrderService[]) => {
	return Object.values(selectedEosServices).reduce(
		(acc, curr) => acc + (curr?.service?.providerPaymentSubTotal || 0),
		0
	);
};

const getProviderRetentionTotal = (selectedEosServices: PreOrderService[]): number => {
	return Object.values(selectedEosServices).reduce(
		(acc, curr) => acc + (curr?.service?.providerPaymentRetention || 0),
		0
	);
};

interface BillingPreOrderProps {
	showConfirmCancelModal: () => void;
	handleDrawerDirection: (direction: string) => void;
	closePreOrder: () => void;
	drawerDirection: string;
	branch: BRANCH;
}

const BillingPreOrder: FunctionComponent<BillingPreOrderProps> = ({
	showConfirmCancelModal,
	handleDrawerDirection,
	closePreOrder,
	drawerDirection,
	branch,
}): JSX.Element => {
	const { selectedEosServices, provider } = useContext(Context) as AppContext;
	const [providerPaymentTotalSum, setProviderPaymentTotalSum] = useState(0);
	const [providerPaymentTaxSum, setProviderPaymentTaxSum] = useState(0);
	const [providerPaymentCostSum, setProviderPaymentCostSum] = useState(0);
	const [providerRetentionBreakdown, setProviderRetentionBreakdown] = useState<Record<
		ProviderRetentionNames,
		ProviderRetentionBreakdown
	> | null>();
	const [providerTotalRetentionSum, setProviderTotalRetentionSum] = useState(0);
	const [files, setFiles] = useState([] as File[]);
	const [xml, setXmlString] = useState<string>('');
	const [xmlErrors, setXmlErrors] = useState<string[]>([]);
	const [xmlFile, setXmlFile] = useState<File | null>(null);
	const [isLoadingFiscalBreakdownFile, setIsLoadingFiscalBreakdownFile] = useState(false);
	const [isLoadingFiscalGuide, setIsLoadingFiscalGuide] = useState(false);
	const [loading, setLoading] = useState(false);
	const externalInvoiceInputRef = useRef<HTMLInputElement>(null);
	const form = useRef<HTMLFormElement>(null);

	const checkSelectedEvents = (): boolean => {
		if (Object.values(selectedEosServices).length === 0) {
			toast.warning('You need to select some events before continue');
			return false;
		}
		return true;
	};

	const renderButtons: { [key: string]: any } = {
		up__85: () => renderFirstStepButtons(),
		up__0: () => renderSecondStepButtons(),
	};

	const renderFirstStepButtons = (): JSX.Element => {
		return (
			<div className="btn-group" role="group">
				<button
					type="button"
					className="billing-pre-order__statuses__btn-continue m-2"
					onClick={() => handleDrawerDirection(DRAWER_DIRECTION_OPENED)}
				>
					Continue
				</button>
				<button type="button" className="billing-pre-order__statuses__btn m-2" onClick={() => showConfirmCancelModal()}>
					Cancel
				</button>
			</div>
		);
	};

	const renderSecondStepButtons = (): JSX.Element => {
		return (
			<div className="btn-group" role="group">
				<button type="submit" className="billing-pre-order__statuses__btn-continue m-2" onClick={() => handleSubmit()}>
					Confirm
					{loading && <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>}
				</button>
				<button
					type="button"
					className="billing-pre-order__statuses__btn m-2"
					onClick={() => handleDrawerDirection(DRAWER_DIRECTION_DEFAULT_STATE)}
				>
					Go back
				</button>
			</div>
		);
	};

	useEffect(() => {
		setXmlErrors([]);
		const newProviderPaymentTotalSum = getProviderPaymentTotalSum(selectedEosServices);
		const newProviderPaymentCostSum = getProviderPaymentCostSum(selectedEosServices);
		const newProviderPaymentTaxSum = getProviderPaymentTaxSum(selectedEosServices);
		const newRetentionBreakdown = getProviderRetentionBreakdown(
			map(selectedEosServices, 'service.providerRetentions'),
			branch
		);
		const newRetentionTotalSum = getProviderRetentionTotal(selectedEosServices);

		setProviderPaymentTotalSum(newProviderPaymentTotalSum);
		setProviderPaymentCostSum(newProviderPaymentCostSum);
		setProviderPaymentTaxSum(newProviderPaymentTaxSum);
		setProviderRetentionBreakdown(newRetentionBreakdown);
		setProviderTotalRetentionSum(newRetentionTotalSum);
	}, [selectedEosServices]);

	const handleSubmit = async (): Promise<any> => {
		if (loading) return;

		if (xmlErrors?.length) {
			toast.error('The XML contains errors');
			return;
		}

		if (xmlIsRequired(branch) && !xmlFile && !provider.billing?.withoutXml) {
			toast.warning('XML File is required');
			return;
		}
		if (branch === BRANCH.CRC && !files?.some((file) => file.type === 'text/xml')) {
			toast.warning('Must load at least 2 XML files');
			return;
		}
		if (pdfIsRequired(branch) && !files?.some((file) => file.type === 'application/pdf')) {
			toast.warning('PDF File is required');
			return;
		}
		if (checkSelectedEvents() && form?.current?.reportValidity()) {
			try {
				setLoading(true);
				const fileReferences: Array<FileReferences> = [];
				const filesToProcess = xmlFile ? [...files, xmlFile] : files;
				for (const file of filesToProcess) {
					const fileReference = await uploadFile(file);
					fileReferences.push(fileReference);
				}
				const serviceNumbers = Object.keys(selectedEosServices).map((serviceNumber: string) => Number(serviceNumber));
				const data: CreateEosInvoiceParams = {
					invoiceNum: externalInvoiceInputRef.current?.value || '',
					branch,
					provider,
					serviceNumbers,
					files: fileReferences,
					xml,
				};

				const response = await new EosClient().createEosInvoice(data);
				toast.success(response?.data?.message);
				setFiles([] as File[]);
				setExternalInvoiceId('');
				closePreOrder();
			} catch (error: Error | any) {
				if (error?.response?.data?.xmlErrors) {
					setXmlErrors(error?.response?.data?.xmlErrors);
					toast.error('The XML contains errors');
				} else {
					const errorMsg = error?.response?.data?.message;
					toast.error(typeof errorMsg === 'string' ? errorMsg : 'Something went wrong, please try again');
				}
			} finally {
				setLoading(false);
			}
		}
	};

	const setExternalInvoiceId = (externalInvoiceId: string) => {
		if (externalInvoiceInputRef.current) {
			externalInvoiceInputRef.current.value = externalInvoiceId;
		}
	};

	const handleDownloadFiscalGuide = async () => {
		try {
			setIsLoadingFiscalGuide(true);
			await downloadFile(process.env.REACT_APP_FISCAL_GUIDE_FILE_MX_URL || '', 'GUIA FISCAL - Comunicado PV.pdf');
		} catch (error) {
			toast.error('There was an error downloading the fiscal guide');
		} finally {
			setIsLoadingFiscalGuide(false);
		}
	};

	const handleDownloadFiscalBreakdown = async () => {
		try {
			setIsLoadingFiscalBreakdownFile(true);
			const serviceNumbers = Object.keys(selectedEosServices).map((serviceNumber: string) => Number(serviceNumber));

			const blob = await new EosClient().getFiscalBreakdown({
				serviceNumbers,
				vendorId: provider?.vendorId,
			});
			saveAs(blob.data, 'desglose-fiscal.xlsx');
		} catch (error) {
			console.error('Error downloading the fiscal breakdown', error);
			toast.error('There was an error downloading the fiscal breakdown');
		} finally {
			setIsLoadingFiscalBreakdownFile(false);
		}
	};

	const renderDetail = (): JSX.Element => {
		return (
			<form className="billing-pre-order__form" ref={form}>
				<div className="billing-pre-order-detail">
					{/* Invoice title */}
					<h5>Create Invoice</h5>

					{/* Invoice header */}
					<p>
						<span className="h6">Provider Name: </span>
						{provider.providerName}
					</p>
					<p>
						<span className="h6">Vendor ID: </span>
						{provider.vendorId}
					</p>
					<p>
						<span className="h6">Tax ID: </span>
						{provider.taxId}
					</p>
					<p>
						<span className="h6">Social Reason: </span>
						{provider.socialReason}
					</p>

					{/* Invoice invoice */}
					<h6 className="mt-4">Add invoice</h6>
					<hr className="header-body-division" />
					<label>
						External Invoice #{' '}
						<input
							readOnly
							{...{ type: 'text', name: 'invoiceNumber', placeholder: 'Type something', required: true }}
							className="external-invoice-input"
							ref={externalInvoiceInputRef}
						/>
					</label>

					{/* Invoice attachments */}
					<Attachments
						branch={branch}
						setFiles={setFiles}
						files={files}
						xmlFile={xmlFile}
						setXmlFile={setXmlFile}
						setXmlString={setXmlString}
						xmlErrors={xmlErrors}
						setXmlErrors={setXmlErrors}
						paymentTotal={providerPaymentTotalSum}
						paymentTaxTotal={providerPaymentTaxSum}
						paymentSubTotal={providerPaymentCostSum}
						paymentRetentionTotal={providerTotalRetentionSum}
						setExternalInvoiceId={setExternalInvoiceId}
					/>
				</div>
				<div className="billing-pre-order-detail__table-container">
					<div className="billing-pre-order-detail__table-container__table">
						<Table branch={branch} services={selectedEosServices} />
					</div>
					<Feature name={FEATURE_FLAG.INVOICE_DETAIL__FISCAL_DOWNLOAD_BUTTONS}>
						<div className="download-actions">
							<Button
								onClick={() => handleDownloadFiscalBreakdown()}
								type="button"
								disabled={isLoadingFiscalBreakdownFile}
								icon={<IconDownload />}
								isLoading={isLoadingFiscalBreakdownFile}
							>
								DESCARGAR DESGLOSE FISCAL
							</Button>
							<Button
								type="button"
								onClick={() => handleDownloadFiscalGuide()}
								isLoading={isLoadingFiscalGuide}
								icon={<IconDownload />}
							>
								DESCARGAR GUÍA FISCAL
							</Button>
						</div>
					</Feature>
				</div>
			</form>
		);
	};

	return (
		<>
			<div className="billing-pre-order">
				{drawerDirection === 'up__0' && renderDetail()}
				<div className="">
					<div className="pull-left">{drawerDirection && renderButtons[drawerDirection]()}</div>
					<div className="pull-right">
						<div className="mt-2 ">
							<label className="billing-pre-order__label fw-bold">Subtotal:&nbsp;</label>
							<label className="billing-pre-order__label">{centsToCurrency(providerPaymentCostSum, branch)}</label>
						</div>
						<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN}>
							{getRetentionsByBranch(branch).map((retention) => (
								<div key={retention}>
									<label className="billing-pre-order__label fw-bold">
										{providerRetentionBreakdown?.[retention].label}:&nbsp;
									</label>
									<label className="billing-pre-order__label">
										<span>{providerRetentionBreakdown?.[retention]?.value || 0 > 0 ? '-' : ''}</span>
										{providerRetentionBreakdown?.[retention].currencyValue}
									</label>
								</div>
							))}
						</Feature>
						<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_TOTAL}>
							<>
								<div>
									<label className="billing-pre-order__label fw-bold">Retention :&nbsp;</label>
									<label className="billing-pre-order__label">
										<span>{providerTotalRetentionSum > 0 ? '-' : ''}</span>
										{centsToCurrency(providerTotalRetentionSum, branch)}
									</label>
								</div>
							</>
						</Feature>
						<div>
							<label className="billing-pre-order__label fw-bold">{getIVALabel(branch)}:&nbsp;</label>
							<label className="billing-pre-order__label">{centsToCurrency(providerPaymentTaxSum, branch)}</label>
						</div>
						<div>
							<label className="billing-pre-order__label fw-bold">Total:&nbsp;</label>
							<label className="billing-pre-order__label">{centsToCurrency(providerPaymentTotalSum, branch)}</label>
							<button type="button" className="billing-pre-order__statuses__btn m-2">
								Selected: <strong>{Object.keys(selectedEosServices).length}</strong>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BillingPreOrder;
