import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface ServiceToolbarProps {
	serviceNumber: number | undefined;
	serviceStatus: string | undefined;
}

const ServiceToolbar: FunctionComponent<ServiceToolbarProps> = ({
	serviceNumber,
	serviceStatus,
}: ServiceToolbarProps): JSX.Element => {
	return (
		<header>
			<div className="service-view__po-number">
				<Link to="/dashboard">
					<i className="fa fa-arrow-left"></i>
				</Link>
				<p>PO NUMBER: {serviceNumber}</p>
			</div>
			<div className="service-view__service-status">STATUS: {serviceStatus}</div>
		</header>
	);
};

export default ServiceToolbar;
