import { FunctionComponent, useState } from 'react';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

import './InvoiceAttachments.scss';

import { InvoiceAttachmentI } from '../../../interfaces';
import S3ManagerService from '../../../utils/s3-manager';
import FileUploader from '../../Commons/FileUploader';
import { FileTypeError } from '../../../utils/Utils';
import EosClient from '../../../api/EosClient';
import {
	fileErrorMessage,
	fileUploaderLabelPdf,
	fileUploaderTypesPdf,
	uploadFile,
} from '../../Commons/FileUploader.utils';
import Modal from '../../Commons/Modal';
import ConfirmModal from '../../Commons/ConfirmModal';

interface InvoiceAttachmentProps {
	invoiceId: number;
	branch: string;
	invoiceAttachments: Array<InvoiceAttachmentI> | any;
	reloadInvoiceAttachments: () => void;
}

const InvoiceAttachment: FunctionComponent<InvoiceAttachmentProps> = ({
	invoiceId,
	invoiceAttachments,
	reloadInvoiceAttachments,
}): JSX.Element => {
	const [handleFileLoading, setHandleFileLoading] = useState(false);
	const [removeFileIsSubmitting, setRemoveFileIsSubmitting] = useState(false);
	const [showRemoveAttachmentFileModal, setShowRemoveAttachmentFileModal] = useState(false);
	const [fileIdToRemove, setFileIdToRemove] = useState<InvoiceAttachmentI | null>(null);

	const removeFile = async (): Promise<void> => {
		try {
			setRemoveFileIsSubmitting(true);
			if (fileIdToRemove?.id) {
				await new EosClient().removeInvoiceAttachmentReference(fileIdToRemove.id);
				toast.success('Invoice attachment was deleted succesfully');
				reloadInvoiceAttachments();
			} else {
				toast.warn('Warning: file was not removed, try again');
			}
		} catch (error) {
			toast.warn('Warning: file was not removed, try again');
		} finally {
			setRemoveFileIsSubmitting(false);
			handleRemoveFileConfirmModal(null);
		}
	};

	const handleRemoveFileConfirmModal = (file: InvoiceAttachmentI | null) => {
		setFileIdToRemove(file);
		setShowRemoveAttachmentFileModal(!showRemoveAttachmentFileModal);
	};

	const downloadFile = async (url: string, fileName: string) => {
		try {
			const fileUrl = await new S3ManagerService().generateDownloadUrl(url);
			if (!fileUrl) {
				throw 'Download file error';
			}
			handleDownload(fileUrl, fileName);
		} catch (error) {
			toast.error('Download file error');
		}
	};

	const handleDownload = (itemUrl: string, itemName: string) => {
		fetch(itemUrl)
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(blob);
				saveAs(url, itemName);
			});
	};

	const handleFile = async (files: File[], errors: FileTypeError[]) => {
		try {
			if (errors.length) throw errors;
			setHandleFileLoading(true);
			if (errors.length > 0) {
				const errorMessage = fileErrorMessage(errors);
				throw new Error(errorMessage);
			}
			for (const file of files) {
				// Call to S3 to upload the file
				const { fileName, filePath } = await uploadFile(file);
				// Call to api to save the file reference (InvoiceAttachments)
				const data = { invoiceId, fileName, filePath };
				await new EosClient().createInvoiceAttachmentsReference(data);
			}
			reloadInvoiceAttachments();
			toast.success('Invoice attachment was updated successfully');
		} catch (error: Error | any) {
			if (error.message) {
				toast.error(error.message);
			} else {
				toast.error('Upload file error');
			}
		} finally {
			setHandleFileLoading(false);
		}
	};

	return (
		<>
			<span className="invoice-attachment-header">
				<h6>Attachments</h6>
				<FileUploader
					handleFile={(files, errors) => handleFile(files, errors)}
					label={fileUploaderLabelPdf()}
					fileTypes={fileUploaderTypesPdf()}
					handleFileLoading={handleFileLoading}
				/>
			</span>
			<div className="invoice-attachment invoice-attachment__list">
				<ul>
					{invoiceAttachments?.map((file: InvoiceAttachmentI) => (
						<li key={file.id}>
							<div className="file-element">
								{file.fileName}
								<span>
									<button
										type="button"
										title="Download file"
										onClick={(): Promise<void> => downloadFile(file.filePath, file.fileName)}
									>
										<i className="fa fa-download"></i>
									</button>
									{!file.fileName?.includes('xml') && (
										<button type="button" title="Remove file" onClick={(): void => handleRemoveFileConfirmModal(file)}>
											<i className="fa fa-trash"></i>
										</button>
									)}
								</span>
							</div>
						</li>
					))}
				</ul>
			</div>
			{showRemoveAttachmentFileModal && (
				<Modal
					dismiss={(): void => {
						handleRemoveFileConfirmModal(null);
					}}
				>
					<ConfirmModal
						onSubmit={() => removeFile()}
						onCancelButtonClick={() => handleRemoveFileConfirmModal(null)}
						isSubmitting={removeFileIsSubmitting}
						modalText={`Do you want to remove this file? ${fileIdToRemove?.fileName}`}
						denyText="No, go back"
						confirmText="Yes, remove this file"
					/>
				</Modal>
			)}
		</>
	);
};

export default InvoiceAttachment;
