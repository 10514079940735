// external imports
import styled from 'styled-components';
// global imports
import BorderedContainer from '@components/Commons/BorderedContainer';
import BaseInput from '@components/Commons/Input';
import BaseDropdown from '@components/Commons/Dropdown';
import { IProps as IBaseDropdownProps } from '@components/Commons/Dropdown/types';
import BaseTextArea from '@components/Commons/TextArea';

interface IStyledInputContainerProps {
	$section?: 'client' | 'vehicle' | 'service' | 'service-kavak' | 'service-vanti';
}

interface IStyledInputProps {
	$gridArea?: string;
}

const FormContainer = styled(BorderedContainer)`
	padding: 69px 50px 94px 50px;
	@media only screen and (max-width: 1056px) {
		padding: 18px 15px 35px 15px;
	}
`;

const FormSectionTitle = styled.h2`
	${({ theme }) => theme.typography.mobileH2};
	color: ${({ theme }) => theme.colors.textoGrisPrimario};
	margin-bottom: 30px;
`;

const InputsContainer = styled.div<IStyledInputContainerProps>`
	display: grid;
	column-gap: 20px;
	row-gap: 30px;
	padding-bottom: 30px;
	&.pre-highlight {
		padding-bottom: 10px;
	}

	${({ $section }) =>
		$section === 'client'
			? `
        grid-template-columns: 315px 315px 315px 1fr;
        grid-template-rows: 55px;
        grid-template-areas:
          'input-1 input-2 input-3 .'
          'input-4 input-5 input-6 .';

        @media only screen and (max-width: 1350px) {
          grid-template-columns: 315px 315px;
          grid-template-rows: 55px;
          grid-template-areas:
            'input-1 input-2'
            'input-3 input-4'
            'input-5 input-6';
        }

        @media only screen and (max-width: 760px) {
          grid-template-columns: 1fr;
          grid-template-rows: 55px;
          grid-template-areas:
            'input-1'
            'input-2'
            'input-3'
            'input-4'
            'input-5'
            'input-6';
        }
  `
			: ''}

	${({ $section }) =>
		$section === 'vehicle'
			? `
        grid-template-columns: 315px 315px 315px 315px;
        grid-template-rows: 55px;
        grid-template-areas:
          'make           other-make    model    other-model'
          'armor-level    year          color    plate'
          'vin            .             .        .';

        @media only screen and (max-width: 1700px) {
          grid-template-columns: 315px 315px 315px;
          grid-template-rows: 55px;
          grid-template-areas:
            'make          model          armor-level'
            'other-make    other-model    year'
            'color         plate          vin';
        }

        @media only screen and (max-width: 1350px) {
          grid-template-columns: 315px 315px;
          grid-template-rows: 55px;
          grid-template-areas:
          'make           other-make'
          'model          other-model'
          'armor-level    year'
          'color          plate'
          'vin            .';
        }

        @media only screen and (max-width: 760px) {
          grid-template-columns: 1fr;
          grid-template-rows: 55px;
          grid-template-areas:
          'make'
          'other-make'
          'model'
          'other-model'
          'armor-level'
          'year'
          'color'
          'plate'
          'vin';
        }
  `
			: ''}

${({ $section }) =>
		$section === 'service-kavak'
			? `
        grid-template-columns: 315px 315px 315px 315px;
        grid-template-rows: 55px;
        grid-template-areas:
          'situation     .              .       '
          'carrier-name  carrier-phone  comments'
          'receiver-name receiver-phone comments';

        @media only screen and (max-width: 1350px) {
          grid-template-columns: 315px 315px;
          grid-template-rows: 55px;
          grid-template-areas:
            'situation     .             '
            'carrier-name  carrier-phone '
            'receiver-name receiver-phone'
            'comments      comments      '
            'comments      comments      ';
        }

        @media only screen and (max-width: 760px) {
          grid-template-columns: 1fr;
          grid-template-rows: 55px;
          grid-template-areas:
            'situation'
            'carrier-name'
            'carrier-phone'
            'receiver-name'
            'receiver-phone'
            'comments'
            'comments';
        }
  `
			: ''}

${({ $section }) =>
		$section === 'service'
			? `
      grid-template-columns: 315px 315px 315px 315px;
      grid-template-rows: 55px;
      grid-template-areas:
        'situation     .              comments'
        'carrier-name  carrier-phone  comments'
        '.             .              .       ';

      @media only screen and (max-width: 1350px) {
        grid-template-columns: 315px 315px;
        grid-template-rows: 55px;
        grid-template-areas:
          'situation     .             '
          'carrier-name  carrier-phone '
          'comments      comments      '
          'comments      comments      ';
      }

      @media only screen and (max-width: 760px) {
        grid-template-columns: 1fr;
        grid-template-rows: 55px;
        grid-template-areas:
          'situation'
          'carrier-name'
          'carrier-phone'
          'comments'
          'comments';
      }
  `
			: ''}

${({ $section }) =>
		$section === 'service-vanti'
			? `
    grid-template-columns: 315px 315px 315px 315px;
    grid-template-rows: 55px;
    grid-template-areas:
      'situation     scheduling-type   novelty-c4c    .'
      'city          date              time           .'
      'comments      .                 .              .'
      'comments      .                 .              .';

    @media only screen and (max-width: 1350px) {
      grid-template-columns: 315px 315px;
      grid-template-rows: 55px;
      grid-template-areas:
        'situation        scheduling-type'
        'novelty-c4c      city '
        'date             time'
        'comments         comments'
        'comments         comments';
    }

    @media only screen and (max-width: 760px) {
      grid-template-columns: 1fr;
      grid-template-rows: 55px;
      grid-template-areas:
        'situation'
        'scheduling-type'
        'novelty-c4c'
        'city'
        'date'
        'time'
        'comments'
        'comments';
    }
`
			: ''}
`;

const BrandIcon = styled.div`
	flex-shrink: 0;
	width: 315px;
	height: 55px;
	padding: 15px 0;
	& > img {
		height: 100%;
	}
`;

const HighlightedInputsContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 30px 20px;
	flex-wrap: wrap;
	background: ${({ theme }) => theme.colors.naranja05};
	margin: 0 -50px;
	padding: 20px 50px 30px;
	@media only screen and (max-width: 1056px) {
		padding: 20px 15px 30px;
		margin: 0 -15px;
	}
	@media only screen and (max-width: 760px) {
		& > ${BrandIcon} {
			order: -1;
		}
	}
`;

const Input = styled(BaseInput)<IStyledInputProps>`
	grid-area: ${({ $gridArea }) => $gridArea};
`;

const HighlightedInput = styled(BaseInput)`
	width: 315px;
	@media only screen and (max-width: 760px) {
		width: 100%;
	}
`;

const Dropdown = styled(BaseDropdown)<IStyledInputProps>`
	grid-area: ${({ $gridArea }) => $gridArea};
` as <T>(props: IBaseDropdownProps<T> & IStyledInputProps) => React.ReactElement;

const TextArea = styled(BaseTextArea)<IStyledInputProps>`
	grid-area: ${({ $gridArea }) => $gridArea};
	& textarea {
		height: 160px;
	}
`;

const Separator = styled.div`
	height: 1px;
	background: ${({ theme }) => theme.colors.gris};
	margin-bottom: 70px;
`;

const S = {
	FormContainer,
	FormSectionTitle,
	InputsContainer,
	HighlightedInputsContainer,
	HighlightedInput,
	BrandIcon,
	Input,
	Dropdown,
	TextArea,
	Separator,
};

export default S;
