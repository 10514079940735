import { Text } from '@connect-assistance/connect-ui';
import styles from './index.module.css';
import useProviderQuoteHistory from '../hooks/useProviderQuoteHistory';
import { formatTimestampToCountryTz } from '@utils/datetime.utils';

interface QuoteHistoryProps {
	note: string;
	serviceId: string;
	name: string;
	branch: string;
}

export function QuoteNote({ note, serviceId, name, branch }: QuoteHistoryProps): JSX.Element {
	const { isLoading, error, quoteHistory } = useProviderQuoteHistory(serviceId);

	if (isLoading) return <Text>Loading quote note...</Text>;
	if (error) return <Text>{`Error: ${error.message}`}</Text>;

	const quoteLog = quoteHistory.find((quote) => quote.name === name)?.logs ?? [];
	const lastLogQuote = quoteLog[quoteLog.length - 1];

	return (
		<div className={styles.quoteNote}>
			<div className={styles.quoteNoteAuthor}>
				<div>
					<Text size="md" appearance="bold" elementType="span">
						Note by:
					</Text>{' '}
					<Text size="md" elementType="span">
						{lastLogQuote.user.name}
					</Text>
				</div>
				<Text size="sm" elementType="span" appearance="italic">
					{formatTimestampToCountryTz(lastLogQuote.timestamp, branch, 'MMMM D, YYYY, h:mm:ss a')}
				</Text>
			</div>
			<Text size="md" elementType="p">
				{note}
			</Text>
		</div>
	);
}
