import { FunctionComponent, useRef, useState } from 'react';
import cn from 'classnames';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import './DashboardMultipleFilters.scss';

interface DashboardMultipleFiltersProps {
	label: string;
	items: any;
	selectedItems: any;
	onItemClick: any;
	removeSelectedItem: any;
}

const DashboardMultipleFilters: FunctionComponent<DashboardMultipleFiltersProps> = ({
	label,
	items,
	selectedItems,
	onItemClick,
	removeSelectedItem,
}): JSX.Element => {
	const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);
	const [tempSearch, setTempSearch] = useState<string>('');

	const selectedItemsNames: string[] = selectedItems.map((item: any) => (item.label ? item.label : item.name));

	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, setDropdownOpened);

	const updateList = (name: string) => {
		if (selectedItemsNames.includes(name)) {
			const index = selectedItemsNames.indexOf(name);
			if (index > -1) selectedItemsNames.splice(index, 1);
		} else selectedItemsNames.push(name);
	};

	const onOptionClick = (option: any) => {
		setDropdownOpened(false);
		if (option.label) {
			updateList(option.label);
			onItemClick(option.label, option.name, option.vendorId);
		} else {
			updateList(option.name);
			onItemClick(option.name, option?.vendorId);
		}
	};

	const onRemoveClick = (option: any): void => {
		if (option.label) {
			updateList(option.label);
			removeSelectedItem(option.label);
		} else {
			updateList(option.name);
			removeSelectedItem(option.name);
		}
	};

	const renderSelectedSituations = (): JSX.Element => {
		return (
			<>
				{(selectedItems || []).length > 0 &&
					(selectedItems || []).slice(0, 2).map((selected: any, idx: number) => (
						<span key={idx} className="selection">
							<span>
								{selected.label || selected.name}
								<i onClick={(): void => onRemoveClick(selected)} className="fa fa-times"></i>
							</span>
						</span>
					))}
				{(selectedItems || []).length > 2 && <span className="selection">+{(selectedItems || []).length - 2}</span>}
			</>
		);
	};

	return (
		<div className="m-filter-container">
			<div onClick={(): void => setDropdownOpened(!dropdownOpened)} className="m-filter-container__filter">
				<input
					className="m-filter-container__input"
					type="text"
					placeholder={`Filter by: ${label}`}
					onChange={(evt) => setTempSearch(evt.target.value)}
					size={12}
				></input>
				{(selectedItems || []).length === 0 && <span></span>}
				{renderSelectedSituations()}
				<i
					className={cn('m-filter-container__icon', {
						'fa fa-chevron-up': dropdownOpened,
						'fa fa-chevron-down': !dropdownOpened,
					})}
				></i>
			</div>
			{dropdownOpened && (
				<div ref={wrapperRef} className="m-filter-container__dropdown">
					{(selectedItems || []).map((option: any, idx: number) => (
						<div key={idx} className="m-filter-container__dropdown__item">
							<span onClick={(): void => onOptionClick(option)} className="checkbox">
								<input type="checkbox" defaultChecked={true} />
								<span id="checkmark"></span>
							</span>
							<span>{option.label || option.name}</span>
						</div>
					))}
					{(items || [])
						.filter((item: any) => {
							return (
								(item.name?.toLowerCase().includes(tempSearch.toLowerCase()) ||
									item.label?.toLowerCase().includes(tempSearch.toLowerCase())) &&
								!selectedItemsNames.includes(item.name) &&
								!selectedItemsNames.includes(item.label)
							);
						})
						.map((option: any, idx: number) => (
							<div key={idx} className="m-filter-container__dropdown__item">
								<span onClick={(): void => onOptionClick(option)} className="checkbox">
									<input
										type="checkbox"
										defaultChecked={
											selectedItemsNames.includes(option.label) || selectedItemsNames.includes(option.name)
										}
									/>
									<span id="checkmark"></span>
								</span>
								<span>{option.label || option.name}</span>
							</div>
						))}
				</div>
			)}
		</div>
	);
};

export default DashboardMultipleFilters;
