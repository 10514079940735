import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
	Button,
	Modal,
	Tabs,
	Text,
	TextField,
	CurrencyTextField,
	TrashCanIcon,
	PlusIcon,
} from '@connect-assistance/connect-ui';
import HeliosClient from '@api/HeliosClient';
import { QuoteHistory } from './components/QuoteHistory';
import styles from './index.module.css';
import { ProviderQuote } from './types';
import { HELIOS_SERVICE_STATUS } from '@utils/constants';
import { QuoteNote } from './components/QuoteNote';
import { toast } from 'react-toastify';
import { ProviderQuoteStatus } from './utils';

type ProviderQuotesModalProps = Pick<React.ComponentProps<typeof Modal>, 'isOpen'> & {
	serviceId: string;
	closeModal: () => void;
	/**
	 * If provided, the modal will open in edit mode with the form fields pre-filled.
	 * Use this to edit an existing quote.
	 */
	quoteData?: ProviderQuote;
	branch: string;
	serviceHasQuotes: boolean;
	serviceStatus: HELIOS_SERVICE_STATUS;
};

type TabKeys = 'create-quote' | 'quote-history';

const quoteItemSchema = yup.object({
	description: yup
		.string()
		.required('Please enter a description for this quote item.')
		.min(1, 'The description cannot be empty.')
		.trim(),
	unitPrice: yup
		.number()
		.required('Please enter a unit price for this item.')
		.positive('The unit price must be a positive number.')
		.min(1, 'The total must be a positive number.'),
	qty: yup
		.number()
		.required('Please enter the quantity for this item.')
		.positive('The quantity must be a positive number.')
		.default(1),
});

const formSchema = yup
	.object({
		generalNote: yup
			.string()
			.required('Please provide a title or description for the quote.')
			.min(1, 'The quote title/description cannot be empty.')
			.trim(),
		quoteItems: yup.array(quoteItemSchema).required().min(1, 'Please add at least one quote item to proceed.'),
	})
	.required();

export default function ProviderQuotesModal({
	serviceId,
	branch,
	isOpen,
	closeModal,
	quoteData,
	serviceHasQuotes,
	serviceStatus,
}: ProviderQuotesModalProps): JSX.Element {
	const { handleSubmit, register, control, formState, watch, getValues, reset } = useForm({
		resolver: yupResolver(formSchema),
		mode: 'onBlur',
		defaultValues: quoteData
			? {
					generalNote: quoteData.description,
					quoteItems: quoteData.items.map((item) => ({
						description: item.description,
						unitPrice: item.subTotal! / item.qty / 100, // Divide subtotal by qty because unitPrice * qty = subtotal, then divide by 100 to convert from cents to dollars
						qty: item.qty,
						subTotal: item.subTotal! / 100,
					})),
				}
			: { generalNote: '', quoteItems: [{ description: '', unitPrice: 0, qty: 1, subTotal: 0 }] },
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'quoteItems',
	});

	const [selectedTab, setSelectedTab] = useState<TabKeys>(
		serviceStatus === HELIOS_SERVICE_STATUS.ACTIVE ? 'create-quote' : 'quote-history'
	);

	const isEditMode = Boolean(quoteData);
	const isEditable = quoteData?.status !== ProviderQuoteStatus.ReviewInProgress;

	const modalProps = React.useMemo(
		() =>
			selectedTab === 'create-quote' && isEditable
				? {
						mainActionButtonText: quoteData ? 'Save' : 'Submit',
						secondaryActionButtonText: 'Cancel',
						mainActionButtonClick: handleSubmit(async () => {
							const values = getValues();
							const heliosClient = new HeliosClient();
							let response;
							try {
								if (quoteData) {
									response = await heliosClient.updateServiceProviderQuote(serviceId, quoteData._id, {
										description: values.generalNote,
										status:
											quoteData.status === ProviderQuoteStatus.Returned
												? ProviderQuoteStatus.PendingReview
												: quoteData.status, // no changes to the quote status
										items: values.quoteItems.map((quoteItem) => ({
											description: quoteItem.description,
											unitPrice: quoteItem.unitPrice * 100, // convert to cents (USD)
											qty: quoteItem.qty,
										})),
									});
								} else {
									response = await heliosClient.createProviderQuote(serviceId, {
										description: values.generalNote,
										items: values.quoteItems.map((quoteItem) => ({
											description: quoteItem.description,
											unitPrice: quoteItem.unitPrice! * 100, // convert to cents (USD)
											qty: quoteItem.qty,
										})),
									});
									reset();
								}
								toast.success(
									`${response.data.data.name} ${
										isEditMode ? 'has been successfully edited' : 'was successfully created'
									}`
								);
								closeModal();
							} catch {
								toast.error(`Something went wrong`);
							}
						}),
						secondaryActionButtonClick: closeModal,
					}
				: {
						mainActionButtonText: 'Close',
						mainActionButtonClick: closeModal,
					},
		[selectedTab, handleSubmit, getValues, reset, closeModal, serviceId, quoteData, isEditMode, isEditable]
	);

	const quoteItems = watch('quoteItems');
	const totalQuotePrice = quoteItems.reduce(
		(accumulator, currentQuoteItem) => accumulator + currentQuoteItem.qty * currentQuoteItem.unitPrice,
		0
	);

	const handleAddQuoteItem = React.useCallback(() => {
		append({ description: '', unitPrice: 0, qty: 1 });
	}, [append]);

	const handleSelectionChange = React.useCallback((key) => setSelectedTab(key as TabKeys), []);

	return (
		<div className={styles.modalWrapper}>
			{/* @ts-expect-error This TS issue will be fixed in the next version of Connect UI */}
			<Modal isOpen={isOpen} title="Quotes" aria-label="Quotes" {...modalProps}>
				<Tabs selectedKey={selectedTab} onSelectionChange={handleSelectionChange}>
					{!serviceHasQuotes && (
						<Tooltip id="quote-history-tooltip" variant="info">
							No quotes have been created for this service
						</Tooltip>
					)}
					<Tabs.List>
						{serviceStatus === HELIOS_SERVICE_STATUS.ACTIVE && (
							<Tabs.Tab id="create-quote">{isEditMode ? 'Edit Quote' : 'Create Quote'}</Tabs.Tab>
						)}
						<Tabs.Tab id="quote-history" isDisabled={!serviceHasQuotes} data-tooltip-id="quote-history-tooltip">
							Quote History
						</Tabs.Tab>
					</Tabs.List>
					<Tabs.Panel id="create-quote">
						{isEditMode && (
							<div className={styles.quoteName}>
								<Text size="md" appearance="bold" elementType="p">{`ID QUOTE ${quoteData?.name}`}</Text>
							</div>
						)}
						{quoteData?.status === ProviderQuoteStatus.Returned && quoteData?.note && (
							<QuoteNote name={quoteData?.name} note={quoteData.note} serviceId={serviceId} branch={branch} />
						)}
						<form>
							<div className={styles.formQuoteWrapper}>
								<Text size="lg">{(isEditMode ? 'Edit' : 'Create') + ' a quote by adding a title and items'}</Text>
								<TextField
									{...register('generalNote', { required: true })}
									label="Title and description of quote"
									errorMessage={formState.errors.generalNote?.message || ''}
									isInvalid={Boolean(formState.errors.generalNote)}
									isRequired
									isClearable
									isDisabled={!isEditable}
								/>
								<div className={styles.quoteItemListWrapper}>
									{/* @ts-expect-error This TS issue will be fixed in the next version of Connect UI */}
									<Button
										appearance="tertiary"
										colorScheme="orange"
										size="xs"
										onPress={handleAddQuoteItem}
										leadingIcon={PlusIcon}
										isDisabled={!isEditable}
									>
										Add new quote item
									</Button>
									<div className={styles.quoteItemWrapper}>
										{fields.map((field, index) => {
											const quoteItemQtyProps = register(`quoteItems.${index}.qty` as const, {
												required: true,
											});
											const quoteItemUnitPriceProps = register(`quoteItems.${index}.unitPrice` as const, {
												required: true,
											});

											return (
												<React.Fragment key={field.id}>
													<TextField
														{...register(`quoteItems.${index}.description` as const, { required: true })}
														label="Item title and details"
														errorMessage={formState.errors.quoteItems?.[index]?.description?.message || ''}
														isInvalid={Boolean(formState.errors.quoteItems?.[index]?.description)}
														isRequired
														isClearable
														isDisabled={!isEditable}
													/>
													<CurrencyTextField
														label="Quantity"
														isRequired
														errorMessage={formState.errors.quoteItems?.[index]?.qty?.message || ''}
														isInvalid={Boolean(formState.errors.quoteItems?.[index]?.qty)}
														ref={quoteItemQtyProps.ref}
														onBlur={quoteItemQtyProps.onBlur}
														defaultValue={`${getValues(`quoteItems.${index}.qty`)}`}
														onValueChange={(_, __, currencyValue) => {
															quoteItemQtyProps.onChange({
																target: { value: currencyValue?.float, name: `quoteItems.${index}.qty` },
															});
														}}
														isDisabled={!isEditable}
														isReadOnly={!isEditable}
													/>
													<CurrencyTextField
														label="Unit price"
														isRequired
														intlConfig={{ locale: 'en-US', currency: 'USD' }}
														errorMessage={formState.errors.quoteItems?.[index]?.unitPrice?.message || ''}
														isInvalid={Boolean(formState.errors.quoteItems?.[index]?.unitPrice)}
														ref={quoteItemUnitPriceProps.ref}
														onBlur={quoteItemUnitPriceProps.onBlur}
														defaultValue={`${getValues(`quoteItems.${index}.unitPrice`)}`}
														onValueChange={(_, __, currencyValue) => {
															quoteItemUnitPriceProps.onChange({
																target: { value: currencyValue?.float, name: `quoteItems.${index}.unitPrice` },
															});
														}}
														isDisabled={!isEditable}
														isReadOnly={!isEditable}
													/>
													<CurrencyTextField
														label="Total price"
														isRequired
														intlConfig={{ locale: 'en-US', currency: 'USD' }}
														value={`${
															getValues(`quoteItems.${index}.unitPrice`) * getValues(`quoteItems.${index}.qty`)
														}`}
														isDisabled
														isReadOnly
													/>
													<button
														type="button"
														onClick={() => remove(index)}
														className={`${fields.length === 1 || !isEditable ? styles.hideDeleteButton : ''} ${
															styles.deleteButton
														}`}
													>
														<TrashCanIcon />
													</button>
												</React.Fragment>
											);
										})}
										<div className={styles.totalQuote}>
											<CurrencyTextField
												label="Total quote price"
												value={`${totalQuotePrice}`}
												intlConfig={{ locale: 'en-US', currency: 'USD' }}
												isDisabled
												isReadOnly
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</Tabs.Panel>
					<Tabs.Panel id="quote-history">
						<QuoteHistory serviceId={serviceId} branch={branch} />
					</Tabs.Panel>
				</Tabs>
			</Modal>
		</div>
	);
}
