import { FunctionComponent, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeliosClient from '@api/HeliosClient';
import LoginArrow from '@assets/imgs/login_arrow.png';
import './styles.scss';

interface RouteParams {
	userType: string;
	token: string;
}

const ResetPassword: FunctionComponent = (): JSX.Element => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [resetSuccess, setResetSuccess] = useState(false);
	const history = useHistory();
	const { userType, token } = useParams<RouteParams>();

	useEffect(() => {
		if (resetSuccess) {
			const timer = setTimeout(() => history.replace('/login'), 3000);
			return (): void => clearTimeout(timer);
		}
	}, [resetSuccess]);

	const requestReset = async (): Promise<void> => {
		try {
			// Helios handles 'admin' as 'users'.
			const heliosUserType = userType === 'admin' ? 'users' : userType;
			const {
				data: { status },
			} = await new HeliosClient().forgotPassword(heliosUserType, { email, app: 'eos' });
			if (status) {
				toast.success('Verify your email for futher instructions. Password reset request sent');
				setEmail('');
				setResetSuccess(true);
			} else {
				toast.error('Password reset request failed.');
				setResetSuccess(false);
			}
		} catch (error) {
			console.log('failed: ', error);
			toast.error('Password reset request failed.');
		}
	};

	const isPasswordValid = (): boolean => {
		const mediumRegex = new RegExp(
			'^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
		);
		return mediumRegex.test(password);
	};

	const resetPassword = async (): Promise<void> => {
		if (!isPasswordValid()) {
			toast.warning('Password does not meet the requirements');
			return;
		}
		const data = {
			email: email.trim(),
			password: password.trim(),
		};

		const response = await new HeliosClient().resetPassword(userType, data, token);

		if (response.status) {
			setResetSuccess(true);
			setEmail('');
			setPassword('');
			toast.success('Password successfully updated');
		} else {
			toast.error('Error');
		}
	};

	return (
		<div className="reset-page">
			<form className="reset-page__form">
				<div className="reset-page__form__header">
					<h5>{token ? 'Reset Password' : 'Forgot Password'}</h5>
				</div>
				<div className="reset-page__form__body">
					{!token && <span>Enter your email address to request a password recovery.</span>}
					<input
						onChange={(evt): void => setEmail(evt.target.value)}
						className="reset-page__form__icon reset-page__form__icon--email"
						type="email"
						placeholder="Email"
					/>
					{token && (
						<input
							className="reset-page__form__icon reset-page__form__icon--password"
							type="password"
							placeholder="Password"
							onChange={(evt): void => setPassword(evt.target.value)}
						/>
					)}
					{token && (
						<div className="reset-page__text">
							<p>
								Password must contain six characters or more and has at least one lowercase and one uppercase
								alphabetical character or has at least one lowercase and one numeric character or has at least one
								uppercase and one numeric character.
							</p>
						</div>
					)}
					{!token ? (
						<div className="reset-page__forgot-password">
							<Link to={`/login/${userType}`}>
								<i className="fa fa-arrow-left"></i>
								<span> Back to Login</span>
							</Link>
							<figure>
								<img onClick={requestReset} src={LoginArrow} alt="login button" />
							</figure>
						</div>
					) : (
						<div className="reset-page__submit-reset">
							<figure>
								<img onClick={resetPassword} src={LoginArrow} alt="login button" />
							</figure>
						</div>
					)}
				</div>
			</form>
		</div>
	);
};

export default ResetPassword;
