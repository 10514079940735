import { BRANCH, USER_TYPE } from '@utils/constants';
import type { ProviderQuote } from '@views/Service/components/ProviderQuotes/types';

export interface Params {
	status: string;
	branch: string;
	page: number;
	limit: number;
	sort: string;
	sfId: string;
	term?: string;
	searchType?: string;
	situations?: string[];
}

export interface SendInvoiceEmailParams {
	email: string;
	preorderNum: string;
	branch: string;
}

export interface UpsertEosServiceStatusRejectedParams {
	serviceNumber: number;
	invoiceId?: number;
	provider: Provider;
	user: User;
	reason: string;
}

export interface ProviderRetention {
	retentionName: string;
	retentionValue: number;
}

export interface ProviderRetentionBreakdown {
	label: string;
	name: string;
	value: number;
	currencyValue: string;
}

export interface EosService {
	id: number;
	serviceNumber: number;
	countryCode: string;
	customerName: string;
	creationDate?: Date;
	dispatchDate?: string;
	routedDate?: Date;
	arrivalDate?: Date;
	closingDate?: Date;
	cancelledDate?: Date;
	status: string;
	plate?: string;
	brand?: string;
	model?: string;
	year?: string;
	insuranceName: string;
	unity: string;
	unityPlate?: string;
	vendorId: string;
	providerDistance: number;
	situation: Situations;
	weightType?: string;
	situationDistance: number;
	cancellationReason?: string;
	eosStatus: string;
	reviewed?: boolean;
	providerName: string;
	phone: string;
	vin?: string;
	providerPaymentTotal: number;
	providerPaymentSubTotal: number;
	providerPaymentTax: number;
	providerPaymentRetention: number;
	driverName?: string;
	serviceCity?: string;
	virtualInspector?: string;
	viClientAccepted?: string;
	branch: string;
	category?: string;
	isPrepaid?: boolean;
	providerRetentions?: ProviderRetention[];
}

export interface IContactAttempts {
	contactedAt: Date;
	contactedBy?: string;
}

export interface Service {
	_account: any;
	_id?: string;
	account: string;
	accountOfExistingCustomerSelected?: string;
	address_home?: string;
	address_payment?: string;
	agent: any;
	agentBranch: any;
	agentId: any;
	agentName: string;
	allowedToFinishFromEOS: boolean;
	amount?: number;
	amountBeforeTax?: number;
	appointmentDate?: string;
	arrivalDuration: string;
	arrivedTime?: string;
	athMovilPayment?: boolean;
	athPayAsABusiness: boolean;
	auditedBy?: string;
	authorizedBy?: string;
	autoMotoraSwitch?: boolean;
	billablePayment?: boolean;
	branch: string;
	category?: string;
	cancellationReason?: string;
	cancellationStatus?: string;
	cancelWithoutFee?: boolean;
	canDispatch: boolean;
	cardIdStripe?: string;
	cashPayment?: boolean;
	cedulaNumber?: number;
	cedulaType?: string;
	channel?: string;
	chargeIdStripe?: string;
	chargeVial?: boolean;
	city_home?: string;
	city_payment?: string;
	clientId?: string;
	color: string;
	contactAccount?: { config: { formAccess: boolean } };
	contactAttempts?: IContactAttempts[];
	contactUpdate?: boolean;
	copagoValue?: number;
	customerContract?: any;
	created?: number;
	createDate?: string;
	creditPayment?: boolean;
	customerIdStripe?: string;
	date: Date;
	destination?: string;
	destinationCity?: string;
	DIDNumber?: string;
	dispatchedDateDay: string;
	dispatchedDateTime: string;
	distance?: number;
	driver: Driver;
	driverId?: string;
	driverName: string | undefined;
	duplicate?: boolean;
	duration?: string;
	editOrDuplicateStatus?: string;
	elap?: string;
	email_payment?: string;
	email?: string;
	employeeEmail?: string;
	employeeId?: string;
	employeeName?: string;
	endDate?: Date;
	estaEnCobertura?: boolean;
	estimatedDistance: number;
	estimatedPrice?: number;
	estimatedProviderDistance?: number;
	external: any;
	extraAmountDeclined?: number;
	extraCharge?: number;
	extraChargeAmountWasDeclined?: boolean;
	fees: { management: number; situation: number };
	files: any;
	finalElapTime?: string;
	finish_lat?: number;
	finish_long?: number;
	finishedTime?: number;
	finishPlaceId?: string;
	firstname?: string;
	firstPaymentType?: string;
	flags: Flags;
	formUrl?: string;
	giftPayment?: boolean;
	goaStatus?: string;
	hasSurplus?: boolean;
	hasUnreadNotes?: boolean;
	incidentDate: Date;
	inspection?: string;
	inspectionData: any;
	inspectionSpec: any;
	insurance: any;
	internal?: { crmCustomerId: number | null; crmVehicleId: number | null; crmInsuranceId: number | null };
	invoiceNum?: string;
	isCovered?: boolean;
	isExistingCustomerFromSalesForce?: boolean;
	lastname_payment?: string;
	lastname?: string;
	locations: Locations;
	make: string;
	manageElapTime: string;
	millajeFinal?: number;
	millajeFinalAfterFinish?: string;
	model: string;
	modified?: number;
	name_payment?: string;
	name?: string;
	nombre_tarjeta?: string;
	notes?: Notes[];
	notification?: Notification;
	numberOfRooms?: string;
	observations?: [];
	onDemand?: boolean;
	onRouteTime: string;
	origin?: string;
	otherName?: string;
	otroStatus?: boolean;
	paymentMethod?: string;
	paymentReason?: string;
	paymentRequestId?: string;
	pdfReportUrl?: string;
	petAge?: string;
	petBreed?: string;
	petName?: string;
	petWeight?: number;
	phone_payment?: string;
	phone1?: string;
	phone2?: string;
	pinFinishRoadAddress?: string;
	pinProviderRoadAddress?: string;
	pinSituationAddress?: string;
	pinStartRoadAddress?: string;
	planMensualAnual?: boolean;
	plate?: string;
	policyNumber?: string;
	policyType?: string;
	policyUnit?: string;
	price?: number;
	prov_start_lat?: number;
	prov_start_long?: number;
	provider?: any;
	providerDistance?: number;
	providerEmail?: string;
	providerId?: string;
	providerName?: string;
	providerPayment: ProviderPayment;
	providerPaymentTotal?: number;
	providerPaymentSubTotal?: number;
	providerPaymentRetention?: number;
	providerPhone?: string;
	providerPhone2?: string;
	provPlaceId: any;
	referenceId?: string;
	rejectReason?: string;
	salesforce: Salesforce;
	schedule?: string;
	scheduleDate?: string;
	serviceCity?: string;
	serviceCityFinish?: string;
	serviceCost?: number;
	serviceNumber: number;
	serviceSource?: string;
	serviceType?: string;
	sfClientId?: number;
	sfId?: string;
	sfIdAccount: string;
	sfIdContact?: string;
	sfIdOfCustomterIfExisting?: string;
	sfIdProvider?: string;
	sfServiceId?: string;
	sicInspection?: string;
	situation: Situations;
	situationLabel?: string;
	snoozeCount: number;
	soldSubscription?: string;
	sourceType?: string;
	start_lat?: number;
	start_long?: number;
	startPlaceId?: string;
	status?: string;
	stripeHoldAmount?: number;
	subscription?: boolean;
	taxAmount?: number;
	taxRate: number;
	thirtynineDiscount?: boolean;
	totalPaidByCustomer?: number;
	towedTime: string;
	trip?: any;
	type?: string;
	typeLabel?: string;
	typeOfProperty?: string;
	updatedTotalPriceInSalesforce?: boolean;
	usedHeliosApp?: boolean;
	veinteDescuentoDeFinishAndNew?: boolean;
	vendorId?: string;
	warnings?: string[];
	version?: number;
	viClientAccepted?: string;
	vin?: string;
	vehicle?: { type: string };
	virtualInspector?: string;
	vposPayment?: boolean;
	waitingDispatchElap?: string;
	year: string;
	zipcode_home?: number;
	zipcode_payment: string;
	isPrepaid?: boolean;
	lastQuote?: Pick<ProviderQuote, '_id' | 'name' | 'status'>;
}

export enum ProviderRetentionNames {
	SITUATION_RETENTION = 'SITUATION_RETENTION',
	PROVIDER_RETENTION = 'PROVIDER_RETENTION',
	PROVIDER_TECH_RETENTION = 'PROVIDER_TECH_RETENTION',
}

export interface ProviderRetentionItem extends Item {
	name: ProviderRetentionNames;
}

interface ProviderPayment {
	costs?: Array<ProviderCostItem>;
	taxes: Array<ProviderTaxItem>;
	taxesTotal: number;
	retentionsTotal: number;
	retentions: Array<ProviderRetentionItem>;
	billableDistance?: number;
}

interface Item {
	label?: string;
	value: number;
}

export type ProviderCostNames =
	| 'wait'
	| 'lock'
	| 'fuel'
	| 'ferry'
	| 'toll'
	| 'parking'
	| 'otherCost'
	| 'dollys'
	| 'desparqueo'
	| 'patines'
	| 'nightFee';

export interface ProviderCostItem extends Item {
	name: ProviderCostNames;
	tax?: number;
	total?: number;
}

export interface ProviderTaxItem extends Item {
	name: 'iva' | ProviderCostNames;
	percent: number;
}

export interface Notes {
	date: Date;
	employeeEmail: string;
	employeeName: string;
	msg: string;
	serviceId: string;
	timestamp: number;
	isObservation: boolean;
	d2iNote: boolean;
	user: { id: string; type: string };
	read: boolean;
	audioUrl: string;
	hideNote?: boolean;
}

export interface Driver {
	_id?: string;
	id?: string;
	app?: { version: string; lastUsed: Date };
	averageResponseTime: number;
	branch: string;
	created: number | Date;
	date: Date;
	email: string;
	employeeImage?: string;
	iconset?: string;
	inspectorId: string;
	name: string;
	onDuty?: string;
	password?: string;
	phone: string;
	providerId: string;
	rating: number;
	skills: string[];
	status: string;
	vehicleImage?: string;
	vehicleType?: string;
	lat: number;
	lng: number;
	tripStatus: string;
	activeId: string;
	acceptedServices: string[];
}

export interface Locations {
	provider: Location;
	situation: Location;
	destination: Location;
}

export interface Location {
	branch?: string;
	address?: string;
	lat?: number;
	lng?: number;
	municipality?: string;
	province?: string;
	district?: string;
	canton?: string;
	placeId?: string;
	referenceAddress?: string;
}

export interface Salesforce {
	accountId?: string;
	clientId: number;
	contactId?: string;
	providerId?: string;
	sendTries: number;
	serviceId?: string;
}

export interface Flags {
	auto?: boolean;
	changeCard?: boolean;
	contactUpdate?: boolean;
	isCovered?: boolean;
	createContact?: boolean;
	duplicate?: boolean;
	edit?: boolean;
	editRefund?: boolean;
	finishAndNew?: boolean;
	isExistingCustomerFromSalesForce?: boolean;
	new?: boolean;
	notCovered?: boolean;
	onDemand?: boolean;
	paymentType?: string;
	previouslyVial?: boolean;
	previousPaymentMethod?: string;
	situationOther?: boolean;
	subscribe?: boolean;
	hasSurplus?: boolean;
	isFinishAndNew?: boolean;
	isUpgradeToTow?: boolean;
	vial?: boolean;
	hasTowLayout?: boolean;
	fromConnectApp?: boolean;
	typeInputCedula?: string;
}

export interface INote {
	date: number | Date;
	employeeId: string;
	serviceId: string;
	msg: string;
	user: { id: string; type: string };
	isObservation: boolean;
	d2iNote: boolean;
	readFromHelios: boolean;
	readFromEos: boolean;
	audioUrl: string;
	audioDuration: number;
}

export interface User {
	_id: string;
	account: string;
	branch: string;
	defaultAccount: string;
	email: string;
	phone: string;
	firstname: string;
	lastname: string;
	password: string;
	roles: Array<string>;
	status: string;
	userImage: string;
	searchCriteria?: string;
	fixedLocations?: Array<FixedLocation>;
	otherServicesAllowed: string[];
	homeServicesAllowed?: string[];
}

export interface Provider {
	_id: string;
	accounts?: any;
	branch: string;
	email: string;
	name: string;
	phone: string;
	providerId: string;
	providerName: string;
	providerType: string;
	roles: Array<string>;
	status: string;
	taxId?: string;
	socialReason?: string;
	vendorId: string;
	zones: Array<string>;
	eosActions?: Array<IEOSProviderAction>;
	billing?: {
		withoutXml: boolean;
	};
}

export interface ServiceType {
	id: string;
	name: string;
	label: string;
	label_es: string;
	type: string;
	fields: Fields;
	branches: string[];
	primary: boolean;
}

export interface Fields {
	firstname: Field;
	lastname: Field;
	othername: Field;
	phone1: Field;
	phone2: Field;
	serviceCost: Field;
	situation: Field;
	destination: Field;
	vehicle: VehicleFields;
}

export interface Field {
	name: string;
	label: string;
	type: string;
	visible: boolean;
	required: boolean;
	optional: boolean;
}

export interface VehicleFields {
	year: Field;
	make: Field;
	model: Field;
	color: Field;
	vin: Field;
	plate: Field;
}

export enum Situations {
	LOCKSMITH = 'locksmith',
	FLAT_TIRE = 'flatTire',
	FUEL_DELIVERY = 'fuelDelivery',
	JUMP_START = 'jumpStart',
	BATTERY_DIAGNOSTIC = 'batteryDiagnostic',
	TOW_COLLISION = 'towCollision',
	TOW_COLLISSION = 'towCollission',
	TOW_BREAKDOWN = 'towBreakdown',
	TOW_TIRE = 'towTire',
	TOW_EXTRACTION = 'towExtraction',
	INSPECTIONS = 'inspections',
	OTHER_SERVICES = 'situationOtherServices',
	VIRTUAL_INSPECTIONS = 'virtualInspection',
	GRUA_POR_EBRIEDAD = 'grua_ebriedad_local',
}

/**
 *  A map of the service type category to the icon name of that category.
 */
export const ServiceTypeCategoryToIcon = new Map<string, string>([
	['Electricity', 'electricity'],
	['Plumbing', 'plumbing'],
	['Home Locksmith', 'home_locksmith'],
	['Glassworks', 'glassworks'],
	['Handyman', 'handyman'],
	['Pest Control', 'pest_control'],
	['Fall Prevention', 'fall_prevention'],
	['Tech Assistance', 'tech_assistance'],
]);

export const SituationsToIcon = [
	'locksmith',
	'flatTire',
	'fuelDelivery',
	'jumpStart',
	'batteryDiagnostic',
	'towCollision',
	'towCollission',
	'towBreakdown',
	'towTire',
	'towExtraction',
	'inspections',
	'situationOtherServices',
	'virtualInspection',
];

export interface HttpDriver {
	_id: string;
	email: string;
	iconType: string;
	initials: string;
	lat: number;
	lng: number;
	phone: string;
	position: Array<number>;
	status: string;
	tripStatus: string;
	vehicleType: string;
	iconset?: string;
	isSura: boolean;
	serviceNumber: number;
	situation: string;
	tts: string | null;
	ttd: string | null;
	situationAddress: string | undefined;
	finishAddress: string | undefined;
	provider: Provider;
}

export type ActiveService = {
	serviceNumber: number;
	situation: string;
	locations: Locations;
	status: string;
	trip: {
		status: string;
		stamps: any;
		live: any;
	};
	driver: {
		email?: string;
		id?: string;
		name?: string;
		phone?: string;
		_id: string;
	};
};

export type DispatchFunction = (action: Action) => void;

export enum ActionType {
	SET_SELECTED_SITUATIONS = 'SET_SELECTED_SITUATIONS',
	SET_SELECTED_DIVISIONS = 'SET_SELECTED_DIVISIONS',
	SET_STATUS_FILTER = 'SET_STATUS_FILTER',
	SET_SEARCHTYPE = 'SET_SEARCHTYPE',
	SET_SEARCHTERM = 'SET_SEARCHTEM',
	LOGOUT = 'LOGOUT',
	SET_SELECTED_PROVIDERS = 'SET_SELECTED_PROVIDERS',
	SET_SELECTED_SOCIAL_REASON = 'SET_SELECTED_SOCIAL_REASON',
	SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
	SET_USER_TYPE = 'SET_USER_TYPE',
	SET_PROVIDER = 'SET_PROVIDER',
	SET_USER = 'SET_USER',
	SET_SHOW_PREORDER_DRAWER = 'SET_SHOW_PREORDER_DRAWER',
	SET_PREORDER_SERVICES = 'SET_PREORDER_SERVICES',
	SET_SELECTED_INVOICES = 'SET_SELECTED_INVOICES',
	SET_EOS_STATUS = 'SET_EOS_STATUS',
	SET_EOS_OTHER_STATUS = 'SET_EOS_OTHER_STATUS',
	SET_SHOW_REJECT_PO_DRAWER = 'SET_SHOW_REJECT_PO_DRAWER',
	SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS',
	SET_SITUATION_TYPES = 'SET_SITUATION_TYPES',
	SET_POLICIES = 'SET_POLICIES',
	SET_PROVIDER_QUOTE_STATUS = 'SET_PROVIDER_QUOTE_STATUS',
}

export interface Action<T = any> {
	type: ActionType;
	data?: T;
}

export interface DropDownOption {
	label: string;
	name: string;
}

interface SelectedProviders {
	name: string;
	vendorId: string;
}

interface SelectedSocialReason {
	name: string;
	vendorId: string;
}

export interface PreOrderService {
	service: EosService;
}

interface selectedEosStatus {
	name: string;
}

interface selectedEosOtherStatus {
	name: string;
}

export interface AppContext {
	dispatch: DispatchFunction;
	searchTerm: string;
	searchType: string;
	selectedSituations: DropDownOption[];
	selectedDivisions: DropDownOption[];
	selectedProviders: SelectedProviders[];
	selectedSocialReason: SelectedSocialReason[];
	status: string;
	currentPage: number;
	userType: USER_TYPE;
	provider: Provider;
	user: User;
	showPreOrderDrawer: boolean;
	selectedEosServices: PreOrderService[];
	selectedEosInvoices: Invoice[];
	selectedEosStatus: selectedEosStatus[];
	selectedEosOtherStatus: selectedEosOtherStatus[];
	showRejectPODrawer: boolean;
	featureFlags: FeatureFlag[];
	situationTypes: ServiceType[];
	selectedPolicies: DropDownOption[];
	selectedProviderQuoteStatus: DropDownOption[];
}

export interface Invoice {
	id: number;
	preorderNum: number;
	invoiceNum: string;
	status: string;
	paymentsTotal: number;
	refundTotal: number;
	totalServices: number;
	paymentDate: Date;
	createdDate: Date;
	branch: string;
	providerName: string;
	vendorId: string;
	invoiceAttachments?: Array<InvoiceAttachmentI>;
	service: Array<EosService>;
	rejectedInvoiceService: Array<RejectedInvoiceService>;
	providerEmail: string;
}

interface RejectedInvoiceService {
	service: EosService;
}

export interface UpsertEosInvoiceStatusParams {
	invoiceId: number;
	services: number[];
	reason: string;
	providerEmail: string;
	provider: Provider;
	user: User;
}

export interface UpsertEosInvoiceApproveParams {
	invoiceId: number;
	services: number[];
	userEmail: string;
}

export interface UpsertEosInvoicePaymentParams {
	invoiceIds: number[];
	services: number[];
	user: User;
}

export type reportType = 'data' | 'report';
export interface DownloadButtonProps {
	from: string;
	filename: string;
	type: reportType;
}

export interface FeatureFlag {
	name: string;
	description?: string;
	enabled: boolean;
	createdDate: Date;
	updatedDate?: Date;
	userTypes?: Array<FeatureFlagItem>;
	branches?: Array<FeatureFlagItem>;
	roles?: Array<FeatureFlagItem>;
	accounts?: Array<FeatureFlagAccountItem>;
}

interface FeatureFlagAccountItem extends FeatureFlagItem {
	accountId: string;
}

interface FeatureFlagItem {
	name: string;
	createdDate: Date;
	updatedDate?: Date;
}

export interface EOSResponse<T> {
	status?: string;
	data: T;
	success?: boolean;
}

export interface InvoiceAttachmentI {
	createdDate: Date;
	fileName: string;
	filePath: string;
	id: number;
	invoiceId: number;
	updatedDate: Date;
}

export interface InvoiceAttachment {
	invoiceId: number;
	fileName: string;
	filePath: string;
}

export interface CreateEosInvoiceParams {
	invoiceNum: string;
	branch: string;
	provider: Provider;
	serviceNumbers: number[];
	files: Array<FileReferences>;
	xml: string;
}

export interface FileReferences {
	fileName: string;
	filePath: string;
}

export interface ConnectServicesClientUploadParams {
	folderRoot: string;
	fileName: string;
	fileExtension: string;
	file: File;
	bucketName?: string;
	bucketRegion?: string;
}

export enum EOSActions {
	EDIT_SERVICE_COST = 'EDIT_SERVICE_COST',
	RESCHEDULE = 'RESCHEDULE',
	MARK_AS_CONFIRM = 'MARK_AS_CONFIRMED',
}

interface IEOSProviderAction {
	action: EOSActions;
}

/** This interface is for controlling the fields that can be updated from EOS */
export interface EditServiceData {
	driver: Driver;
	schedule: string;
	serviceCost: number;
}

export interface GeoJSON {
	type: string;
	coordinates: [number, number]; // [longitude, latitude]
}

export interface FixedLocation {
	_id: string;
	label: string;
	coordinates: GeoJSON;
	createdAt: string;
	updatedAt: string;
}

export interface ProviderCostField {
	placeholder: string;
	fieldName: string;
}

export interface IOtherCostsFieldConfig {
	visible: boolean;
	editable: boolean;
}

export interface IBranchConfig {
	name: BRANCH;
	maxEmployeesTimeToAcceptInMillis: number;
	minEmployeesQualitySurveyScore: number;
	acceptableEmployeeEtaPercentageError: number;
	providerCostsToAddTax?: ProviderCostNames[];
	providerCostsWithTaxIncluded?: ProviderCostNames[];
	skipProvPaymentOnNonGOACancellation?: boolean;
	retentionsBySituation?: { [key: string]: number };
	otherCostsFieldsConfig: { [key: string]: IOtherCostsFieldConfig };
}
